import React,{useState} from "react";
import { Link } from "react-router-dom";

const DetailFilters = (props) => {
    const {ToggleHideSearch ,hideSearch} = props;
  return (
    <>
    <div className={hideSearch ? "result_filter detail_filter_flex less_top" : "result_filter detail_filter_flex"}>
        <div className="contain-fluid big_contain_fluid">
            <div className="filter_result_flex detail_filter_result_flex">
                <div className="_col">
                    <div className="filter_blk">
                        <div className="back_lbl_nav">
                            <Link to="/"><img src="/images/back_arrow.svg" alt="" /> <span>Property List</span></Link>
                        </div>
                        <div class="drop_filter">
                            <button class="filter_btn site_btn blank yellow_blank"><span>Label</span><img src="/images/label_circle.svg" alt="" /></button>
                        </div>
                        <div class="drop_filter">
                            <button class="filter_btn site_btn"><span>Nearby properties</span><img src="/images/building.svg" alt="" /></button>
                        </div>
                        <div class="drop_filter">
                            <button class="filter_btn site_btn blank yellow_blank"><span>Print</span><img src="/images/report_circle.svg" alt="" /></button>
                        </div>
                        <button className="site_btn hide_detail_search_btn" onClick={ToggleHideSearch}><span>View Search</span><img src="/images/eye_circle_hide.svg" alt="" /></button>
                    </div>
                </div>
                
                <div className="_col">
                    <div className="filter_blk">
                        <button class="filter_btn site_btn blank yellow_blank"><span>Open in new tab</span><img src="/images/minimize_icon.svg" alt="" /></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
    </>
  );
};

export default DetailFilters;

import React from "react";
import { Link } from "react-router-dom";

const SearchBanner = () => {
   
  return (
    <>
      <section className="property_listing_search">
        <div className="contain">
            <h3>Search properties</h3>
            <div className="hide_cell_link_advance">
              <Link to="/search-property">Advance Search</Link>
            </div>
            <div className="search_relative">
                <input type="text" placeholder="Search by address, location, or owner" className="input"/>
                <button className="site_btn only_search_icon"><span className="hide_sm_src">Search</span> <span className="hide_big_src"><img src="/images/search_green.svg" alt="" /></span></button>
            </div>
        </div>
      </section>
    </>
  );
};

export default SearchBanner;

import React,{useState} from "react";
import Filters from "./Filters";
import ResultFilters from "./Result-filter";
import MobileAdvance from "./Mobile-advance";

const OwnersFilter = (props) => {
    const[hideSearch , setHideSearch] = useState(false);
    const ToggleHideSearch = () => {
        setHideSearch(!hideSearch);
        console.log(hideSearch);
    }
  return (
    <>
    <MobileAdvance />
    <Filters ToggleHideSearch={ToggleHideSearch} hideSearch = {hideSearch}/>
    <ResultFilters ToggleHideSearch={ToggleHideSearch} hideSearch = {hideSearch}/>
    <section className={hideSearch ? "search_result_page owner_filter_sec owner_filter_tbl" : "search_result_page filter_show_after owner_filter_sec owner_filter_tbl"}>
        <div className="contain-fluid big_contain_fluid">
            <div className="outer_table">
                <div className="highlight_div">
                    <h5>621 Owners</h5>
                </div>
                <div className="flex">
                    <div className="col_all col_1">
                        <div className="flex">
                            <div className="sm_icon">
                                <img src="/images/chart-tree.svg" alt="" />
                            </div>
                            <div className="_cntnt">
                                <h4>Jen Iv Lp</h4>
                                <p>Mike Brady (North Texas Regional Partner)</p>
                            </div>
                        </div>
                    </div>
                    <div className="col_all">
                        <small>Properties In Search</small>
                        <p>130</p>
                    </div>
                    <div className="col_all">
                        <small>Properties In Portfolio</small>
                        <p>1,170</p>
                    </div>
                    <div className="col_all">
                        <small>Portfolio Assessed Value</small>
                        <p>$24,640,437</p>
                    </div>
                    <div className="col_all">
                        <small>Last Acquisition Date</small>
                        <p>Oct 2022</p>
                    </div>
                    <div className="col_all">
                        <small>Owner Location</small>
                        <p>NY</p>
                    </div>
                    <div className="col_all">
                        <button className="site_btn blank">View 5 Contacts</button>
                    </div>
                </div>
                <div className="flex">
                    <div className="col_all col_1">
                        <div className="flex">
                            <div className="sm_icon">
                                <img src="/images/chart-tree.svg" alt="" />
                            </div>
                            <div className="_cntnt">
                                <h4>Jen Iv Lp</h4>
                                <p>Mike Brady (North Texas Regional Partner)</p>
                            </div>
                        </div>
                    </div>
                    <div className="col_all">
                        <small>Properties In Search</small>
                        <p>130</p>
                    </div>
                    <div className="col_all">
                        <small>Properties In Portfolio</small>
                        <p>1,170</p>
                    </div>
                    <div className="col_all">
                        <small>Portfolio Assessed Value</small>
                        <p>$24,640,437</p>
                    </div>
                    <div className="col_all">
                        <small>Last Acquisition Date</small>
                        <p>Oct 2022</p>
                    </div>
                    <div className="col_all">
                        <small>Owner Location</small>
                        <p>NY</p>
                    </div>
                    <div className="col_all">
                        <button className="site_btn blank">View 5 Contacts</button>
                    </div>
                </div>
                <div className="flex">
                    <div className="col_all col_1">
                        <div className="flex">
                            <div className="sm_icon">
                                <img src="/images/chart-tree.svg" alt="" />
                            </div>
                            <div className="_cntnt">
                                <h4>Jen Iv Lp</h4>
                                <p>Mike Brady (North Texas Regional Partner)</p>
                            </div>
                        </div>
                    </div>
                    <div className="col_all">
                        <small>Properties In Search</small>
                        <p>130</p>
                    </div>
                    <div className="col_all">
                        <small>Properties In Portfolio</small>
                        <p>1,170</p>
                    </div>
                    <div className="col_all">
                        <small>Portfolio Assessed Value</small>
                        <p>$24,640,437</p>
                    </div>
                    <div className="col_all">
                        <small>Last Acquisition Date</small>
                        <p>Oct 2022</p>
                    </div>
                    <div className="col_all">
                        <small>Owner Location</small>
                        <p>NY</p>
                    </div>
                    <div className="col_all">
                        <button className="site_btn blank">View 5 Contacts</button>
                    </div>
                </div>
                <div className="flex">
                    <div className="col_all col_1">
                        <div className="flex">
                            <div className="sm_icon">
                                <img src="/images/chart-tree.svg" alt="" />
                            </div>
                            <div className="_cntnt">
                                <h4>Jen Iv Lp</h4>
                                <p>Mike Brady (North Texas Regional Partner)</p>
                            </div>
                        </div>
                    </div>
                    <div className="col_all">
                        <small>Properties In Search</small>
                        <p>130</p>
                    </div>
                    <div className="col_all">
                        <small>Properties In Portfolio</small>
                        <p>1,170</p>
                    </div>
                    <div className="col_all">
                        <small>Portfolio Assessed Value</small>
                        <p>$24,640,437</p>
                    </div>
                    <div className="col_all">
                        <small>Last Acquisition Date</small>
                        <p>Oct 2022</p>
                    </div>
                    <div className="col_all">
                        <small>Owner Location</small>
                        <p>NY</p>
                    </div>
                    <div className="col_all">
                        <button className="site_btn blank">View 5 Contacts</button>
                    </div>
                </div>
                <div className="flex">
                    <div className="col_all col_1">
                        <div className="flex">
                            <div className="sm_icon">
                                <img src="/images/chart-tree.svg" alt="" />
                            </div>
                            <div className="_cntnt">
                                <h4>Jen Iv Lp</h4>
                                <p>Mike Brady (North Texas Regional Partner)</p>
                            </div>
                        </div>
                    </div>
                    <div className="col_all">
                        <small>Properties In Search</small>
                        <p>130</p>
                    </div>
                    <div className="col_all">
                        <small>Properties In Portfolio</small>
                        <p>1,170</p>
                    </div>
                    <div className="col_all">
                        <small>Portfolio Assessed Value</small>
                        <p>$24,640,437</p>
                    </div>
                    <div className="col_all">
                        <small>Last Acquisition Date</small>
                        <p>Oct 2022</p>
                    </div>
                    <div className="col_all">
                        <small>Owner Location</small>
                        <p>NY</p>
                    </div>
                    <div className="col_all">
                        <button className="site_btn blank">View 5 Contacts</button>
                    </div>
                </div>
                <div className="flex">
                    <div className="col_all col_1">
                        <div className="flex">
                            <div className="sm_icon">
                                <img src="/images/chart-tree.svg" alt="" />
                            </div>
                            <div className="_cntnt">
                                <h4>Jen Iv Lp</h4>
                                <p>Mike Brady (North Texas Regional Partner)</p>
                            </div>
                        </div>
                    </div>
                    <div className="col_all">
                        <small>Properties In Search</small>
                        <p>130</p>
                    </div>
                    <div className="col_all">
                        <small>Properties In Portfolio</small>
                        <p>1,170</p>
                    </div>
                    <div className="col_all">
                        <small>Portfolio Assessed Value</small>
                        <p>$24,640,437</p>
                    </div>
                    <div className="col_all">
                        <small>Last Acquisition Date</small>
                        <p>Oct 2022</p>
                    </div>
                    <div className="col_all">
                        <small>Owner Location</small>
                        <p>NY</p>
                    </div>
                    <div className="col_all">
                        <button className="site_btn blank">View 5 Contacts</button>
                    </div>
                </div>
                <div className="flex">
                    <div className="col_all col_1">
                        <div className="flex">
                            <div className="sm_icon">
                                <img src="/images/chart-tree.svg" alt="" />
                            </div>
                            <div className="_cntnt">
                                <h4>Jen Iv Lp</h4>
                                <p>Mike Brady (North Texas Regional Partner)</p>
                            </div>
                        </div>
                    </div>
                    <div className="col_all">
                        <small>Properties In Search</small>
                        <p>130</p>
                    </div>
                    <div className="col_all">
                        <small>Properties In Portfolio</small>
                        <p>1,170</p>
                    </div>
                    <div className="col_all">
                        <small>Portfolio Assessed Value</small>
                        <p>$24,640,437</p>
                    </div>
                    <div className="col_all">
                        <small>Last Acquisition Date</small>
                        <p>Oct 2022</p>
                    </div>
                    <div className="col_all">
                        <small>Owner Location</small>
                        <p>NY</p>
                    </div>
                    <div className="col_all">
                        <button className="site_btn blank">View 5 Contacts</button>
                    </div>
                </div>
                <div className="flex">
                    <div className="col_all col_1">
                        <div className="flex">
                            <div className="sm_icon">
                                <img src="/images/chart-tree.svg" alt="" />
                            </div>
                            <div className="_cntnt">
                                <h4>Jen Iv Lp</h4>
                                <p>Mike Brady (North Texas Regional Partner)</p>
                            </div>
                        </div>
                    </div>
                    <div className="col_all">
                        <small>Properties In Search</small>
                        <p>130</p>
                    </div>
                    <div className="col_all">
                        <small>Properties In Portfolio</small>
                        <p>1,170</p>
                    </div>
                    <div className="col_all">
                        <small>Portfolio Assessed Value</small>
                        <p>$24,640,437</p>
                    </div>
                    <div className="col_all">
                        <small>Last Acquisition Date</small>
                        <p>Oct 2022</p>
                    </div>
                    <div className="col_all">
                        <small>Owner Location</small>
                        <p>NY</p>
                    </div>
                    <div className="col_all">
                        <button className="site_btn blank">View 5 Contacts</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
      
    </>
  );
};

export default OwnersFilter;

import React,{useState} from "react";
import { Link } from "react-router-dom";
import Filters from "../search/Filters";
import OwnerFilter from "./Owner-filter";
import PropertyChart from "./Property-chart";
import MobileAdvance from "../search/Mobile-advance";
const OwnerOverview = () => {
  const[hideSearch , setHideSearch] = useState(false);
  const ToggleHideSearch = () => {
      setHideSearch(!hideSearch);
      console.log(hideSearch);
  }
  return (
    <>
    <MobileAdvance />
      <Filters ToggleHideSearch={ToggleHideSearch} hideSearch = {hideSearch}/>
      <OwnerFilter ToggleHideSearch={ToggleHideSearch} hideSearch = {hideSearch}/>
      <section className={hideSearch ? "table_view_sec companies_table_page owner_overview_page" : "table_view_sec companies_table_page owner_overview_page with_filter_sec"}>
        <div className="contain-fluid">
            <h4 className="main_heading">Properties Overview</h4>
            <div className="chart_flex flex">
                <div className="col">
                    <div className="inner">
                        <p>Property Type</p>
                        <h4>by property count</h4>
                        <div className="mid_text">
                          <span>22</span> Properties
                        </div>
                        <div><PropertyChart /></div>
                    </div>
                </div>
                <div className="col">
                    <div className="inner">
                        <p>Property Type</p>
                        <h4>by Assessed value</h4>
                        <div className="mid_text">
                          <span>$6.4m</span> Assessed <br/>Value
                        </div>
                        <div><PropertyChart /></div>
                    </div>
                </div>
                <div className="col">
                    <div className="inner">
                        <p>Property Type</p>
                        <h4>by Building area</h4>
                        <div className="mid_text">
                          <span>107k</span> Square <br/>Feet
                        </div>
                        <div><PropertyChart /></div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default OwnerOverview;

import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Authentication = ({page, setPage, data}) => {
  
  return (
    <>

        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, times: [2, 3, 2] }}
        exit={{ opacity: 0 }}
        >
            <fieldset>
                <h4>{data.title3}</h4>
                <p>{data.detail3}</p>
                <div className="blk_form">
                    <div class="form_blk">
                    <ul className="code_verify">
                            <li>
                                <input type="text" name="" id="" class="input" placeholder="" />
                            </li>
                            <li>
                                <input type="text" name="" id="" class="input" placeholder="" />
                            </li>
                            <li>
                                <input type="text" name="" id="" class="input" placeholder="" />
                            </li>
                            <li>
                                <input type="text" name="" id="" class="input" placeholder="" />
                            </li>
                            <li>
                                <input type="text" name="" id="" class="input" placeholder="" />
                            </li>
                    </ul>
                    </div>
                </div>
                <div className="dont_acc">
                    <p>Didn’t get code <Link to="/">Resend code</Link></p>
                </div>
                <div className="br"></div>
                <div className="btn_blk">
                <button className="site_btn" type="button" onClick={() => {
                    alert("You've successfully submitted this form");
                    }}>
                Verify
                </button>
                </div>
                
            </fieldset>
        </motion.div>
    </>
  );
};

export default Authentication;

import React from "react";

const Feature = ({data}) => {
  return (
    <>
      <section className="feature_sec">
        <div className="contain">
            <div className="flex">
                {data?.map((val , i)=>{
                    return(
                        <div className="col" key={i}>
                            <div className="icon_sm">
                                <img src={val.icon} alt="" />
                            </div>
                            <p>{val.label}</p>
                        </div>
                    );
                })}
            </div>
        </div>
      </section>
    </>
  );
};

export default Feature;

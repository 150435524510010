import React from "react";
import { Link } from "react-router-dom";
import Search from "./Search";

const SearchFound = () => {
    const search = {
        banner:{
            heading:"How can we help?",
        },
        questions:[
          {
            id:1,
            title:"What ownership information is provided on each property?",
            pera:"The Owner tab is comprised owners and contacts collected from public sources as well as data from our exclusive data partners. We have been able to pierce through shell LLCs and identify true owners to ensure you are granted the most accurate access to property ownership details.",
            dp:"DR",
            auth_name:"Diana Richards",
            time:"Updated a over a week ago",
            lnk:"/question-article"
          },
          {
            id:2,
            title:"What are Rhino Property Recommendations?",
            pera:"You may have received emails from Rhino which include Reonomy recommended properties. These recommendations are determined from an algorithm that identifies relevant properties to send based on your past activity in the application. ",
            dp:"AA",
            auth_name:"Alison anam",
            time:"Updated a over a week ago",
            lnk:"/question-article"
          },
          {
            id:3,
            title:"How can I add notes to a property?",
            pera:"Click into the text box to record your note. This information is always private to your individual account. are determined from an algorithm that identifies relevant properties to send based on your past activity in the application. ",
            dp:"DR",
            auth_name:"Diana Richards",
            time:"Updated a over a week ago",
            lnk:"/question-article"
          },
          {
            id:4,
            title:"How can I tell if a property is likely to sell?",
            pera:"The Rhino recon Data Science Team has created a new model considering various data points including sales dates, asset types, and MSA to identify properties most likely to transact within the next 2 years across asset categories.",
            dp:"CM",
            auth_name:"Crux Mesaz",
            time:"Updated a over a week ago",
            lnk:"/question-article"
          },
        ]
    }
  return (
    <>
      <Search data={search.banner}/>
      <section className="ans_blk">
        <div className="contain">
            <div className="main_title">
                <p>Search results for: <span>what is property?</span></p>
            </div>
            <ul className="ques_lst">
              {search.questions?.map((val,i)=>{
                return(
                  <li key={i}>
                      <Link to={val.lnk}></Link>
                      <h5>{val.title}</h5>
                      <p>{val.pera}</p>
                      <div className="auther_lbl">
                        <div className="auther_icon">
                          <span>{val.dp}</span>
                        </div>
                        <div className="auther_cntnt">
                          <h6>Written by <span>{val.auth_name}</span></h6>
                          <p>{val.time}</p>
                        </div>
                      </div>
                  </li>
                );
              })}
              
              
            </ul>
        </div>
      </section>
    </>
  );
};

export default SearchFound;

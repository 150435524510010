import React from "react";

const Team = ({data}) => {

  return (
    <>
      <section className="abt_team">
        <div className="contain">
            <div className="flex">
                {data?.map((val , i) => {
                    return(
                        <div className="col" key={i}>
                            <h3>{val.name}</h3>
                            <h4>{val.position}</h4>
                            <p>{val.intro}</p>
                            <div className="image">
                                <img src={val.image} alt={val.name} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
      </section>
    </>
  );
};

export default Team;

import React from "react";
import { Link } from "react-router-dom";

const Notifications = () => {
  return (
    <>
      <div className="setting_blk wide_sm">
            <h4>Notifications</h4>
            <form action="">
                <div className="from_row row">
                    <div className="col-sm-12">
                        <div className="blk_form">
                            <h5 className="sub_acc_heading">General</h5>
                            <div className="switch_flex cell_bottom_mrgin_none">
                                <div className="cntnt">
                                <p>Get notifications from Rhino Recon to stay up-to-date.</p>
                                </div>
                                <div className="switch">
                                    <input type="checkbox" name="notify" id="notify" />
                                    <em></em>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="divide"></div>
                        <div className="blk_form mr_b_0">
                            <h5 className="sub_acc_heading">Emails</h5>
                            <div className="check_lbl">
                                <div className="lbl_btn">
                                    <input type="checkbox" name="team_related" id="team_related"/>
                                    <label htmlFor="team_related">Receive team-related product emails</label>
                                </div>
                                <div className="lbl_btn">
                                    <input type="checkbox" name="recommendation" id="recommendation"/>
                                    <label htmlFor="recommendation">Receive property recommendation updates</label>
                                </div>
                                <div className="lbl_btn">
                                    <input type="checkbox" name="membership" id="membership"/>
                                    <label htmlFor="membership">Receive membership information such as renewal</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="divide"></div>
                        <div className="blk_form mr_b_0">
                            <h5 className="sub_acc_heading">Email Newsletter</h5>
                            <div className="flex radio_email">
                                <div className="lbl_btn">
                                    <input type="radio" name="radio_email" id="radio_email_on"/>
                                    <label htmlFor="radio_email_on">On</label>
                                </div>
                                <div className="lbl_btn">
                                    <input type="radio" name="radio_email" id="radio_email_off"/>
                                    <label htmlFor="radio_email_off">Off</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divide"></div>
                <div className="btn_blk text-right cell_wide_full">
                    <button type="submit" className="site_btn">Save changes</button>
                </div>
            </form>
      </div>
    </>
  );
};

export default Notifications;
import React from "react";
import { Link } from "react-router-dom";

const Billing = () => {
  return (
    <>
      <div className="setting_blk wide_md cell_min_padding">
            <h4>Billing</h4>
            <div className="mini_color_text mini_color_text_semi hide_cell_text_acc">Card Information</div>
            <ul className="flex_ul hide_cell_text_acc">
                <li><span>Credit card ending with 7898</span> <span>Expires: Jul 2025</span></li>
                <li><Link to="/" className="mini_color_text">Update Card info</Link></li>
            </ul>
            <div className="table_blk">
                <div className="inner_tbl">
                    <table className="cell_hide_dowload_lbl">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Statements ID</th>
                                <th>Amount</th>
                                <th className="mini_color_text"><div className="mini_color_text">Next Billing on: 28<span className="cell_block">Sep, 2022</span></div></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Dec 9, 2022</td>
                                <td>344227716</td>
                                <td>$399.0	</td>
                                <td>
                                    <div className="down_load_lbl">
                                        <span>Download</span>
                                        <img src="/images/download_icon.svg" alt="" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Dec 9, 2022</td>
                                <td>344227716</td>
                                <td>$399.0	</td>
                                <td>
                                    <div className="down_load_lbl">
                                        <span>Download</span>
                                        <img src="/images/download_icon.svg" alt="" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
      </div>
    </>
  );
};

export default Billing
import React from "react";
import Banner from "./Banner";
import Feature from "./Feature";
import UploadTables from "./Uploads-tables";

const UploadData = () => {
    const data = {
        banner:{
            heading:"Data Upload",
            text:"Upload a list of properties and quickly append your existing file with Rhino data."
        },
        feature:[
            {
                id:1,
                icon:"/images/mini-icon1-1.svg",
                label:"Upload a csv file with a list of properties"
            },
            {
                id:2,
                icon:"/images/mini-icon2-1.svg",
                label:"Append your data with Rhino’s enriched data"
            },
            {
                id:3,
                icon:"/images/mini-icon3-1.svg",
                label:"choose a new or existing label for your list"
            },
            {
                id:4,
                icon:"/images/mini-icon4-1.svg",
                label:"View and export your appended data"
            },
        ],
        record:{
            heading:"Uploads",
            button_lbl:"Upload File",
            tbl:[
                {
                    id:1,
                    date:"Dec 9, 2022",
                    status:"--",
                    records:"--"
                },
                {
                    id:1,
                    date:"Dec 7, 2022",
                    status:"--",
                    records:"--"
                }
            ]
        }
    }
  return (
    <>
      <Banner data={data.banner}/>
      <Feature data={data.feature}/>
      <UploadTables data={data.record}/>
    </>
  );
};

export default UploadData;

import React from "react";
import { Link } from "react-router-dom";

const FeaturedPost = ({data}) => {
  return (
    <>
      <div className="dir_col flex">
         <Link to={`/blog-detail/${data.id}`} className="image">
            <img src={data.image} alt="" />
         </Link>
         <div className="cntnt">
            <div className="cat_lbl">{data.category}</div>
            <h4><Link to={`/blog-detail/${data.id}`}>{data.title}</Link></h4>
            <p>{data.intro}</p>
         </div>
      </div>
    </>
  );
};

export default FeaturedPost;

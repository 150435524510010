import React from "react";
import Card from "./Card";
import Intro from "./Intro";

const Signup = () => {
    const log = {
        card : {
            image:"/images/property.png",
            popLbl:"POPULAR",
            infoLbl:"Also owns",
            infoLnkLbl:"3 other properties",
            infoLnk:"/",
            phone:"+1234567890",
            email:"john@doe.com",
            position:"Owner",
            name:"John Doe",
            btnLbl:"Contact Now",
            btnLnk:"/",
        },
        intro:{
            title1:"Welcome to the sales revolution!",
            detail1:"Rhinos plow through every opportunity with grit and determination. Let's make some sales!",
            title2:"Company info",
            detail2:"Please enter your company information below.",
            title3:"Authenticate your account",
            detail3:"We just sent you a code to validate your email address, check your inbox."
        },
        right_detail:{
          details:"<h3>Why Rhino Recon?</h3><p>Find more opportunities, connect with owners instantly, and analyze properties with ease.</p><ul><li>Our interactive map will help you find target properties.</li><li>Get the right data, including sales, tax, and debt details.</li><li>Find property owners and talk to the right person in seconds.</li></ul>"
       }
    }
  return (
    <>
      <section className="logon">
        <div className="contain">
            <div className="flex">
                <Intro data = {log.intro}/>
                <div className="colR">
                    <div className="right_pera_details" dangerouslySetInnerHTML={ {__html: log.right_detail.details} } />
                    <div className="br"></div>
                    <Card data = {log.card}/>
                </div>
            </div>
        </div>
        <div className="lg_image">
            <img src="/images/logon.png" alt="" />
        </div>
      </section>
    </>
  );
};

export default Signup;

import React from "react";

const SearchMap = (props) => {
   const {hideSearch} = props;
  return (
    <>
    <section className={hideSearch ? "search_map less_top_search_map" : "search_map"}>
        <div className="contain-fluid fix_cell_result_lbl">
            <p>10,325 properties available</p>
        </div>
        <div id="map">
            <iframe src="https://rhinoreconbranch.000webhostapp.com/" width="100%" frameBorder="0" allowFullScreen></iframe>
        </div>
    </section>
    </>
  );
};

export default SearchMap;

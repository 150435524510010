import * as React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);
const PropertyChart = () => {
    const data = {
        labels: ['Industrial (14%)', 'Retail (18%)', 'Vacant Land (68%)'],
        datasets: [
          {
            data: [14, 18, 68],
            backgroundColor: [
              '#2F2300',
              '#DFC7B0',
              '#67773F',
            ],
            borderWidth: 0,
            
          },
        ],
      };
  return (
    <>
        <Doughnut 
        options={{
        cutout: 80,
        maintainAspectRatio: true,
        plugins: {
            legend: {
            position: "bottom",
            labels: {
                usePointStyle: true,
                pointStyle: "rect",
                padding: 10,
            }
            }
        }
        }}
        data={data} />
    </>
  );
};

export default PropertyChart;

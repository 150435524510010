import React from "react";
import Banner from "./Banner";
import Searches from "./Searches";

const SavedSearches = () => {
    const data = {
        banner:{
            heading:"Saved Search",
        }
    }
  return (
    <>
      <Banner data={data.banner}/>
      <Searches />
    </>
  );
};

export default SavedSearches;

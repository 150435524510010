import React from "react";
import { Link } from "react-router-dom";

const Searches = ({data}) => {
  return (
    <>
      <section className="searches_saved_sec">
        <div className="contain-fluid">
            <div className="searches_blk">
                <h4>Multifamily New Owner Leads</h4>
                <div className="flex">
                    <button>Building area min: 10k sf</button>
                    <button>Property type: Multifamily</button>
                    <button>Sales: Post 2 years</button>
                    <button>Sales: Post 2 years</button>
                    <button>Property type: Multifamily</button>
                </div>
                <div className="time">1 month ago</div>
                <div className="act_btn">
                    <Link to="/" className="edit"><img src="/images/edit-icon.svg" alt="" /></Link>
                    <Link to="/" className="delete"><img src="/images/delete.svg" alt="" /></Link>
                </div>
            </div>
            <div className="devide_line"></div>
            <div className="searches_blk">
                <h4>Renovation leads</h4>
                <div className="flex">
                    <button>Built upto 2022</button>
                    <button>Property type: Multifamily</button>
                    <button>Sales: Post 2 years</button>
                    <button>Property type: Multifamily</button>
                </div>
                <div className="time">A week ago</div>
                <div className="devide_line"></div>
                <div className="act_btn">
                    <Link to="/" className="edit"><img src="/images/edit-icon.svg" alt="" /></Link>
                    <Link to="/" className="delete"><img src="/images/delete.svg" alt="" /></Link>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default Searches;

import React from "react";
import Form from "./Form";

const Intro = ({data}) => {
  return (
    <>
      <div className="colL">
        <div className="inner">
            <h4>{data.title}</h4>
            <p>
                {data.detail}
            </p>
            <Form />
        </div>
      </div>
    </>
  );
};

export default Intro;

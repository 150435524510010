import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import {useLocation } from "react-router-dom";
    const ResultFilters = (props) => {
    const {ToggleHideSearch , hideSearch} = props;
    const[report,setReport] = useState(false);
    const[sort,setSort] = useState(false);
    const[label,setLabel] = useState(false);
    // const[owners,setOwners] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
  const location_path = location.pathname;
  const current_page = location_path.split('/')[1];
    const ToggleReport = () => {
        setReport(!report);
    }
    const ToggleLabel = () => {
        setLabel(!label);
    }
    const ToggleOwners = () => {
        // setOwners(!owners);
        navigate('/owners');
    }
    const ToggleProperties = () => {
        // setOwners(!owners);
        navigate('/search-result');
    }
    const ToggleSort = () => {
        setSort(!sort);
    }
    
  return (
    <>
    <div className={hideSearch ? "result_filter less_top" : "result_filter"}>
        <div className="contain-fluid big_contain_fluid">
            <div className="filter_result_flex">
                {/* <div className="_col">
                    <div className="main_filter_title">
                        <h4>303 Park St, Atlanta, TX 75551</h4>
                        <p>& 10,325 properties available</p>
                    </div>
                </div> */}
                <div className="_col">
                    <div className="filter_blk">
                        <button className={current_page=='search-result' ? "site_btn" : "site_btn blank yellow_blank"} onClick={ToggleProperties}>Properties</button>
                        <div class="drop_filter drop_result_btn owner_filter_tbl">
                            <button className={current_page=='owners' ? "site_btn" : "site_btn blank yellow_blank"} onClick={ToggleOwners}>Owners</button>
                            {/* <div className={owners ? "drop_cnt active" : "drop_cnt"}>
                                <div className="outer_table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Owner</th>
                                                <th>Properties In Search</th>
                                                <th>Properties In Portfolio</th>
                                                <th>Portfolio Assessed Value</th>
                                                <th>Last Acquisition Date</th>
                                                <th>Owner Location</th>
                                                <th>Contact Information</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="flex">
                                                        <div className="sm_icon">
                                                            <img src="/images/chart-tree.svg" alt="" />
                                                        </div>
                                                        <div className="_cntnt">
                                                            <h4>Jen Iv Lp</h4>
                                                            <p>Mike Brady (North Texas Regional Partner)</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>130</td>
                                                <td>1,170</td>
                                                <td>$24,640,437</td>
                                                <td>Oct 2022</td>
                                                 
                                                <td>NY</td>
                                                <td>
                                                    <button className="site_btn blank">View 5 Contacts</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="flex">
                                                        <div className="sm_icon">
                                                            <img src="/images/chart-tree.svg" alt="" />
                                                        </div>
                                                        <div className="_cntnt">
                                                            <h4>Green Brick Partners, Inc.</h4>
                                                            <p>Mike Brady (North Texas Regional Partner)</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>40</td>
                                                <td>1,170</td>
                                                <td>$24,640,437</td>
                                                <td>Oct 2022</td>
                                                 
                                                <td>TX</td>
                                                <td>
                                                    <button className="site_btn blank">View 75 Contacts</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="flex">
                                                        <div className="sm_icon">
                                                            <img src="/images/chart-tree.svg" alt="" />
                                                        </div>
                                                        <div className="_cntnt">
                                                            <h4>Ashton Woods Usa L.L.C</h4>
                                                            <p>Mike Brady (North Texas Regional Partner)</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>40</td>
                                                <td>1,170</td>
                                                <td>$24,640,437</td>
                                                <td>Oct 2022</td>
                                                 
                                                <td>GA</td>
                                                <td>
                                                    <button className="site_btn blank">View 2 Contacts</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="flex">
                                                        <div className="sm_icon">
                                                            <img src="/images/chart-tree.svg" alt="" />
                                                        </div>
                                                        <div className="_cntnt">
                                                            <h4>Jen Iv Lp</h4>
                                                            <p>Mike Brady (North Texas Regional Partner)</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>130</td>
                                                <td>1,170</td>
                                                <td>$24,640,437</td>
                                                <td>Oct 2022</td>
                                                 
                                                <td>NY</td>
                                                <td>
                                                    <button className="site_btn blank">View 5 Contacts</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="flex">
                                                        <div className="sm_icon">
                                                            <img src="/images/chart-tree.svg" alt="" />
                                                        </div>
                                                        <div className="_cntnt">
                                                            <h4>Green Brick Partners, Inc.</h4>
                                                            <p>Mike Brady (North Texas Regional Partner)</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>40</td>
                                                <td>1,170</td>
                                                <td>$24,640,437</td>
                                                <td>Oct 2022</td>
                                                 
                                                <td>TX</td>
                                                <td>
                                                    <button className="site_btn blank">View 75 Contacts</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="flex">
                                                        <div className="sm_icon">
                                                            <img src="/images/chart-tree.svg" alt="" />
                                                        </div>
                                                        <div className="_cntnt">
                                                            <h4>Ashton Woods Usa L.L.C</h4>
                                                            <p>Mike Brady (North Texas Regional Partner)</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>40</td>
                                                <td>1,170</td>
                                                <td>$24,640,437</td>
                                                <td>Oct 2022</td>
                                                 
                                                <td>GA</td>
                                                <td>
                                                    <button className="site_btn blank">View 2 Contacts</button>
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div> */}
                        </div>
                        <div class="drop_filter drop_result_btn hide_main_filter_new_result_big">
                            <button class="filter_btn site_btn blank yellow_blank" onClick={ToggleLabel}><span>Label</span><img src="/images/label_circle.svg" alt="" /></button>
                            <div className={label ? "drop_cnt medium active" : "drop_cnt medium"}>
                                <div className="head_label_flex">
                                    <span>Label properties</span>
                                    <input type="text" defaultValue={"1"}/>
                                    <span>to</span>
                                    <input type="text" defaultValue={"2"}/>
                                </div>
                                <div className="flex_label_new_new">
                                    <div className="col_left_lbl">
                                        <div className="label_btn red_lbl">
                                            <span>good one</span>
                                            <span>(1)</span>
                                        </div>
                                        <div className="label_btn black_lbl">
                                            <span>Unblocked Owners</span>
                                            <span>(5)</span>
                                        </div>
                                    </div>
                                    <div className="col_right_lbl">
                                        <button><img src="/images/pencil.svg" alt="" /></button>
                                    </div>
                                </div>
                                <div className="new_lbl_plus_sign">
                                    <button>New label +</button>
                                </div>
                                <button className="new_label_properties_new">Label Properties</button>
                            </div>
                        </div>
                        <div class="drop_filter drop_result_btn hide_main_filter_new_result_big">
                            <button class="filter_btn site_btn blank yellow_blank" onClick={ToggleReport}><span>Report</span><img src="/images/report_circle.svg" alt="" /></button>
                            <div className={report ? "drop_cnt medium active" : "drop_cnt medium"}>
                                <div className="header_text text-center">
                                    <h5>Print properties upto</h5>
                                </div>
                                <form action="">
                                    <div className="form_blk">
                                        <h6>Report title</h6>
                                        <input type="text" className="input" placeholder="Title"/>
                                    </div>
                                    <div className="form_blk">
                                        <div className="inner text-center">
                                            <div className="carbe_icon">
                                                <img src="/images/carbon_upload.svg" alt="" />
                                            </div>
                                            <h6>Choose logo</h6>
                                        </div>
                                    </div>
                                    <button className="site_btn" type="button">Create report</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="_col">
                    <div className="filter_blk">
                        <div class="drop_filter drop_result_btn hide_main_filter_new_result_small">
                            <button class="filter_btn site_btn blank yellow_blank" onClick={ToggleLabel}><span>Label</span><img src="/images/label_circle.svg" alt="" /></button>
                            <div className={label ? "drop_cnt medium active" : "drop_cnt medium"}>
                                <div className="head_label_flex">
                                    <span>Label properties</span>
                                    <input type="text" defaultValue={"1"}/>
                                    <span>to</span>
                                    <input type="text" defaultValue={"2"}/>
                                </div>
                                <div className="flex_label_new_new">
                                    <div className="col_left_lbl">
                                        <div className="label_btn red_lbl">
                                            <span>good one</span>
                                            <span>(1)</span>
                                        </div>
                                        <div className="label_btn black_lbl">
                                            <span>Unblocked Owners</span>
                                            <span>(5)</span>
                                        </div>
                                    </div>
                                    <div className="col_right_lbl">
                                        <button><img src="/images/pencil.svg" alt="" /></button>
                                    </div>
                                </div>
                                <div className="new_lbl_plus_sign">
                                    <button>New label +</button>
                                </div>
                                <button className="new_label_properties_new">Label Properties</button>
                            </div>
                        </div>
                        <div class="drop_filter drop_result_btn hide_main_filter_new_result_small">
                            <button class="filter_btn site_btn blank yellow_blank" onClick={ToggleReport}><span>Report</span><img src="/images/report_circle.svg" alt="" /></button>
                            <div className={report ? "drop_cnt medium active" : "drop_cnt medium"}>
                                <div className="header_text text-center">
                                    <h5>Print properties upto</h5>
                                </div>
                                <form action="">
                                    <div className="form_blk">
                                        <h6>Report title</h6>
                                        <input type="text" className="input" placeholder="Title"/>
                                    </div>
                                    <div className="form_blk">
                                        <div className="inner text-center">
                                            <div className="carbe_icon">
                                                <img src="/images/carbon_upload.svg" alt="" />
                                            </div>
                                            <h6>Choose logo</h6>
                                        </div>
                                    </div>
                                    <button className="site_btn" type="button">Create report</button>
                                </form>
                            </div>
                        </div>
                        <button className="site_btn">Export List</button>
                        <div class="drop_filter drop_result_btn cell_top_high_mrgn">
                            <button class="filter_btn site_btn blank yellow_blank" onClick={ToggleSort}><span>Sort by: Year renovated</span><img src="/images/filter_circle.svg" alt="" /></button>
                            <div className={sort ? "drop_cnt height_cnt_drop_bdy exact_wide active" : "drop_cnt height_cnt_drop_bdy exact_wide"}>
                               <div className="option_lst">
                                    <button>Building Area</button>
                                    <button className="active">Property Type</button>
                                    <button>Lot Area</button>
                                    <button>Mortage amount</button>
                                    <button>Last Mortagege date</button>
                                    <button>Last Sale Date</button>
                                    <button>Standardized Lender</button>
                                    <button>Sales Price</button>
                                    <button>Sales Price/SF Building areas</button>
                                    <button>Sales Price//Acre</button>
                                    <button>Total Units</button>
                                    <button>Total Assessed Value</button>
                                    <button>Year Built</button>
                                    <button>Year Renovated</button>
                                    <button>Likely to Sell</button>
                                    <button>Tax Year</button>
                                    <button>Tax Amount</button>
                                    <button>Tax Percent</button>
                                    <button>YoY Change in Tex Amount</button>
                                    <button>Viewed Date</button>
                                    <button>Note Created</button>
                               </div>
                            </div>
                        </div>
                        <button class={hideSearch ? "filter_btn site_btn view_searches_btn" : "after_filter_view filter_btn site_btn view_searches_btn"} onClick={ToggleHideSearch}><span>View Search</span><img src="/images/show_search_icon.svg" alt="" /></button>
                        <button className="togg_icon_search cell_show_map"><img src="/images/list_icon.svg" alt="" /></button>
                        <button className="togg_icon_search active cell_show_map"><img src="/images/map_circle.svg" alt="" /></button>
                    </div>
                </div>
                <div className="_col">
                    <div className="filter_blk">
                        <button className="togg_icon_search"><img src="/images/list_icon.svg" alt="" /></button>
                        <button className="togg_icon_search active"><img src="/images/map_circle.svg" alt="" /></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
    </>
  );
};

export default ResultFilters;

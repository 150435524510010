import React from "react";
import FeaturedPost from "./Featured-post";
import Posts from "./Posts";

const BlogPage = ({data}) => {
  return (
    <>
      <section className="blog">
        <div className="contain">
            <div className="feature_blog">
                <h2>{data.feature_heading}</h2>
                <FeaturedPost data={data.featured_post}/>
            </div>
            <div className="all_post">
                <h4>{data.heading}</h4>
                <Posts data={data.all_posts}/>
            </div>
        </div>
      </section>
    </>
  );
};

export default BlogPage;

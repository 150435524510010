import React from "react";
import { Link } from "react-router-dom";

const BlogDetails = ({data}) => {
  return (
    <>
      <section className="blog">
        <div className="contain">
            <div className="blog_image">
                <Link to="/"><img src="/images/left_angle.svg" alt="" /> <span>Back to blog</span></Link>
                <img src="/images/blog-detail.png" alt="" />
            </div>
            <div className="detail_cntnt">
                <div className="heading_blog">
                    <h4>SERVPRO Partners with Reonomy to Unlock New Depth of Commercial Market Intelligence</h4>
                    <div className="auther_info">
                        <div className="sm_icn">
                            <img src="/images/user.svg" alt="" />
                        </div>
                        <div className="_cnt">
                            <h6>Taylor Barbiery</h6>
                            <p>Head of services</p>
                        </div>
                    </div>
                </div>
                <ul className="social_lnk_blog">
                    <li><Link to="/"><img src="/images/color_fb.svg" alt="" /></Link></li>
                    <li><Link to="/"><img src="/images/color_twitter.svg" alt="" /></Link></li>
                    <li><Link to="/"><img src="/images/color_linkedin.svg" alt="" /></Link></li>
                </ul>
                <div className="ckEditor blog_cntnt">
                    <p>Consider this: almost 95% of shoppers read online reviews before making a purchase, and displaying reviews can increase conversion rates by up to 270%. With numbers such as these, it’s crystal-clear that customer reviews are an important part of every eCommerce store owner’s strategy.</p>
                    <div className="br"></div>
                    <h4>Asking for a review during the wrong time</h4>
                    
                    <p>Consider this: almost 95% of shoppers read online reviews before making a purchase, and displaying reviews can increase conversion rates by up to 270%. With numbers such as these, it’s crystal-clear that customer reviews are an important part of every eCommerce store owner’s strategy.</p>
                    <div className="br"></div>
                    <img src="/images/bl1.png" alt="" />
                    <div className="br"></div>
                    <p>There’s a time and place for everything… including asking for reviews. For instance: you should not be asking for a review on your checkout page. The sole purpose of this page is to guide your customer to complete their purchase, and this means that the page should be as minimalist and pared-down as possible. You don’t want to have any unnecessary elements or Call To Actions (CTAs) that distract them from this goal.</p>
                </div>
            </div>
        </div>
      </section>
      <section className="similar_posts">
        <div className="contain">
            <div className="main_heading">
                <h4>Similiar Articles</h4>
            </div>
            <div className="flex">
                <div className="col">
                    <div className="inner">
                        <Link to="/" className="image">
                        <img src="/images/bl2.png" alt="" />
                        </Link>
                        <div className="cntnt">
                            <h5>
                                <Link to="/">Not an expert? Not a problem. Here’s why.</Link>
                            </h5>
                            <p>Worried about teaching or leading because you’re not an ‘expert’? Research says that’s an advantage. Learn how to leverage your inexperience for better products.</p>
                            <div className="auther_info">
                                <div className="sm_icn">
                                    <img src="/images/user.svg" alt="" />
                                </div>
                                <div className="_cnt">
                                    <h6>Taylor Barbiery</h6>
                                    <p>Head of services</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="inner">
                        <Link to="/" className="image">
                        <img src="/images/bl3.png" alt="" />
                        </Link>
                        <div className="cntnt">
                            <h5>
                                <Link to="/">Self care for entrepreneurs (Yes, you need it)</Link>
                            </h5>
                            <p>Avoid burnout, reduce stress, and keep yourself healthy with these practical self-care tips for entrepreneurs.</p>
                            <div className="auther_info">
                                <div className="sm_icn">
                                    <img src="/images/user.svg" alt="" />
                                </div>
                                <div className="_cnt">
                                    <h6>Taylor Barbiery</h6>
                                    <p>Head of services</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="inner">
                        <Link to="/" className="image">
                        <img src="/images/bl4.png" alt="" />
                        </Link>
                        <div className="cntnt">
                            <h5>
                                <Link to="/">Not an expert? Not a problem. Here’s why.</Link>
                            </h5>
                            <p>Worried about teaching or leading because you’re not an ‘expert’? Research says that’s an advantage. Learn how to leverage your inexperience for better products.</p>
                            <div className="auther_info">
                                <div className="sm_icn">
                                    <img src="/images/user.svg" alt="" />
                                </div>
                                <div className="_cnt">
                                    <h6>Taylor Barbiery</h6>
                                    <p>Head of services</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;

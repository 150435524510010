import React from "react";
import { Link } from "react-router-dom";

const ViewdProperties = ({data}) => {
   
  return (
    <>
      <section className="property_list_grid property_list_sec">
        <div className="contain">
            <h4 className="light_heading f_s_24">{data.heading}</h4>
            <div className="flex">
                {data.property_list?.map((val,i) => {
                    return(
                        <div className="_col" key={i}>
                            {val.popular == "true" ?
                            <div className="property_badge">
                                <img src="/images/star.svg" alt="" />
                                <span>POPULAR</span>
                            </div>
                             : 
                             ""}
                            
                            <div className="inner">
                                <Link to={val.link} className="abst_link"></Link>
                                <div className="cntnt">
                                    <h4 className="black_text color_text f_s_24">{val.price} <sub>| {val.date}</sub></h4>
                                    <h4 className="bold_text f_s_24">{val.title}</h4>
                                    <p>{val.address}</p>
                                    <div className="like_btn">
                                        <button>
                                        <img
                                            src="/images/heart.svg"
                                            alt=""
                                        />
                                        </button>
                                    </div>
                                </div>
                                
                                <div className="divide_line"></div>
                                <ul>
                                    {val.accommodation?.map((acc,index)=>{
                                        return(
                                            <li key={index}>
                                                <img src={acc.img} alt={acc.accommo} />
                                                <span dangerouslySetInnerHTML={{__html: acc.accommo}} />
                                                
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    );
                })}
                
            </div>
        </div>
      </section>
    </>
  );
};

export default ViewdProperties;

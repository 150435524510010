import React from "react";
import { Link } from "react-router-dom";

const ResultProperties = (props) => {
    const {hideSearch} = props;
    const {data} = props;
  return (
    <>
      <section className={hideSearch ? "search_result_page" : "search_result_page filter_show_after"}>
        <div className="flex listing_relative">
            <div className="colL">
                <div className="outer_properties">
                    {data.properties?.map((val,i) => {
                        return(
                            <div className="inner_properties" key={i}>
                                <div className="dt_cnt">
                                    <div className="col">
                                        <div className="price_date">
                                            <h5><strong>${val.price}</strong> | {val.date}</h5>
                                            <h3><Link to={val.link}>{val.title}</Link></h3>
                                            <p className="dim_text">{val.build}</p>
                                        </div>
                                    </div>
                                    <div className="col hide_cell_text_col">
                                        <h4>Owner</h4>
                                        <p className="dim_text">{val.owner}</p>
                                    </div>
                                </div>
                                <div className="dt_foot">
                                    <div className="col">
                                        <ul className="info">
                                            <li><img src="/images/bed.svg" alt="4 Beds" /><span>{val.beds}</span></li>
                                            <li><img src="/images/bath.svg" alt="3 Bathrooms" /><span>{val.baths}</span></li>
                                            <li><img src="/images/dimension.svg" alt="8x10 m²" /><span>{val.square}</span></li>
                                        </ul>
                                    </div>
                                    <div className="col">
                                        <div className="show_cell_text_col">
                                            <h4>Owner</h4>
                                            <p className="dim_text">{val.owner}</p>
                                        </div>
                                        <ul className="social">
                                            <li>
                                                <Link to={val.email}>
                                                    <img src="/images/email-icon.svg" alt="" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={val.call}>
                                                    <img src="/images/call-icon.svg" alt="" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="cta_opt_lbl">
                                    <Link to="/">
                                        <img src="/images/ticket_star.svg" alt="" />
                                    </Link>
                                    <Link to="/">
                                        <img src="/images/maximize.svg" alt="" />
                                    </Link>
                                    <Link to="/">
                                        <img src="/images/heart_ico.svg" alt="" />
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                    
                </div>
            </div>
            <div className="colR">
                <div className="outer_map">
                    <div className="map">
                        <iframe src="https://rhinoreconsearchfilterbranch.000webhostapp.com/" width="100%" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default ResultProperties;

import React,{useState} from "react";
import Filters from "./Filters";
import FiltersSearch from "./Filters-search";
import SearchMap from "./Search-map";
import MobileAdvance from "./Mobile-advance";

const SearchProperty = () => {
  const[hideSearch , setHideSearch] = useState(false);
  const ToggleHideSearch = () => {
      setHideSearch(!hideSearch);
      console.log(hideSearch);
  }
  return (
    <>
      <MobileAdvance />
      <FiltersSearch ToggleHideSearch={ToggleHideSearch} hideSearch = {hideSearch}/>
      <SearchMap hideSearch = {hideSearch}/>
    </>
  );
};

export default SearchProperty;

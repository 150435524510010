import React from "react";
import SearchBanner from "./Search-banner";
import ViewdProperties from "./Viewd-properties";
import ViewdOwners from "./Viewd-owners";
import PropertyPost from "./Property-post";

const PropertyListing = () => {
   const property = {
    heading:"Recently viewed properties",
    property_list : [
      {
        id:"1",
        price:"$45k",
        popular:"true",
        date:"Debt on Jul 2022",
        title:"Faulkner Ave",
        address:"909 Woodland St, Michigan, IN",
        link:"/",
        accommodation: [
          {
            id:"1",
            accommo:"4 <span>Beds</span>",
            img:"/images/bed.svg",
          },
          {
            id:"2",
            accommo:"3 <span>Bathrooms</span>",
            img:"/images/bath.svg",
          },
          {
            id:"3",
            accommo:"8x10 m²",
            img:"/images/dimension.svg",
          }
        ]
      },
      {
        id:"2",
        price:"$240k",
        popular:"false",
        date:"Sold on Dec 2022",
        title:"St. Crystal",
        address:"210 US Highway, Highland Lake, FL",
        link:"/",
        accommodation: [
          {
            id:"1",
            accommo:"4 <span>Beds</span>",
            img:"/images/bed.svg",
          },
          {
            id:"2",
            accommo:"2 <span>Bathrooms</span>",
            img:"/images/bath.svg",
          },
          {
            id:"3",
            accommo:"6x8 m²",
            img:"/images/dimension.svg",
          }
        ]
      },
      {
        id:"3",
        price:"$160k",
        popular:"false",
        date:"Sold on Dec 2022",
        title:"Tarpon Bay",
        address:"103 Lake Shores, Michigan, IN",
        link:"/",
        accommodation: [
          {
            id:"1",
            accommo:"3 <span>Beds</span>",
            img:"/images/bed.svg",
          },
          {
            id:"2",
            accommo:"3 <span>Bathrooms</span>",
            img:"/images/bath.svg",
          },
          {
            id:"3",
            accommo:"5x7 m²",
            img:"/images/dimension.svg",
          }
        ]
      }
    ]
   }
   const owner = {
    heading:"Recently viewed owners",
    owner_listing:[
      {
        id:"1",
        designation:"Property owner",
        dp:"DR",
        name:"Diana Richards",
        address:"Owns 1 property Billings, MT",
        link:"/"
      },
      {
        id:"2",
        designation:"Property owner",
        dp:"DR",
        name:"Diana Richards",
        address:"Owns 1 property Billings, MT",
        link:"/"
      },
      {
        id:"3",
        designation:"Property owner",
        dp:"DR",
        name:"Diana Richards",
        address:"Owns 1 property Billings, MT",
        link:"/"
      },
    ]
   }
   const property_post = {
    heading:"Get the most out of Rhino",
    post:[
      {
        id:"1",
        image:"/images/bl2.png",
        link:"/",
        title:"Get familiar to new property page design.",
      },
      {
        id:"2",
        image:"/images/bl1.png",
        link:"/",
        title:"Get familiar to new property page design.",
      },
      {
        id:"3",
        image:"/images/bl3.png",
        link:"/",
        title:"Get familiar to new property page design.",
      },
      {
        id:"4",
        image:"/images/bl4.png",
        link:"/",
        title:"Get familiar to new property page design.",
      }
    ]
   }
  return (
    <>
      <SearchBanner />
      <ViewdProperties data={property}/>
      <ViewdOwners data={owner}/>
      <PropertyPost data={property_post}/>
    </>
  );
};

export default PropertyListing;

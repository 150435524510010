import React from "react";

const Detail = ({data}) => {
  return (
    <>
      <section className="detail_sec">
        <div className="contain">
            <div className="ckEditor" dangerouslySetInnerHTML={ {__html: data.text} }></div>
        </div>
      </section>
    </>
  );
};

export default Detail;

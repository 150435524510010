import React,{useState} from "react";
import Filters from "./Filters";
import DetailFilters from "./Detail-filter";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MobileAdvance from "./Mobile-advance";

const PropertyDetails = () => {
    const[hideSearch , setHideSearch] = useState(false);
    const ToggleHideSearch = () => {
        setHideSearch(!hideSearch);
        console.log(hideSearch);
    }
  return (
    <>
    <MobileAdvance />
      <Filters ToggleHideSearch={ToggleHideSearch} hideSearch = {hideSearch}/>
      <DetailFilters ToggleHideSearch={ToggleHideSearch} hideSearch = {hideSearch}/>
      <section className={hideSearch ? "search_result_page detail_property_page" : "search_result_page detail_property_page filter_show_after"}>
        <div className="flex listing_relative">
            <div className="colL">
                <div className="map_new">
                    <img src="/images/map_new.jpg" alt="" />
                </div>
                <div className="inner">
                    <div className="like_btn">
                        <button>
                        <img
                            src="/images/heart_ico.svg"
                            alt=""
                        />
                        </button>
                    </div>
                    <p className="sm_pera">APN 0649 - 178</p>
                    <h4>303 Park St, Atlanta, TX 75551</h4>
                    <p className="sm_pera">APN 0649 - 178</p>
                    <div className="tabs_filter">
                        <Tabs>
                            <TabList>
                                <Tab><button className="site_btn blank">Building & Lot</button></Tab>
                                <Tab><button className="site_btn blank">Owner</button></Tab>
                                <Tab><button className="site_btn blank">Occupants</button></Tab>
                                <Tab><button className="site_btn blank">Sales</button></Tab>
                                <Tab><button className="site_btn blank">Debts</button></Tab>
                                <Tab><button className="site_btn blank">Taxs</button></Tab>
                                <Tab><button className="site_btn blank">Notes</button></Tab>
                            </TabList>
                            <TabPanel>
                                <div className="lot_blk_flex">
                                    <div className="col">
                                        <div className="lot_info_blk">
                                            <h4>Building</h4>
                                            <ul>
                                                <li><span>Year built</span><span>2015</span></li>
                                                <li><span>Year renovated</span><span>2018</span></li>
                                                <li><span>Stories</span><span>2</span></li>
                                                <li><span>Building Area</span><span>9,060 sqft</span></li>
                                                <li><span>Parking Area</span><span>Yes</span></li>
                                                <li><span>Commercial Unit</span><span>--</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="lot_info_blk">
                                            <h4>Lot</h4>
                                            <ul>
                                                <li><span>Property Type</span><span>Multifamily</span></li>
                                                <li><span>Lot area SF</span><span>25,849 sf</span></li>
                                                <li><span>Lot area acres</span><span>0.59 acres</span></li>
                                                <li><span>Zoning</span><span>--</span></li>
                                                <li><span>Depth</span><span>--</span></li>
                                                <li><span>Census Track</span><span>48120424200</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="lot_info_blk">
                                    <h4>Location</h4>
                                    <ul>
                                        <li><span>Metropolitan Statistical Area</span><span>--</span></li>
                                        <li><span>County</span><span>United States</span></li>
                                        <li><span>Municipality</span><span>Atlanta</span></li>
                                        <li><span>Minor Civil Division</span><span>Atlanta</span></li>
                                        <li><span>Neighborhood</span><span>--</span></li>
                                        <li><span>Legal</span><span>J RICHEY ABST 877 BLK SEC D TR 198-B .594 AC</span></li>
                                    </ul>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="owner_blk_detail">
                                    <div className="owner_card">
                                        <div className="card_head">
                                            <p>Property Owner</p>
                                            <div className="locate_right">
                                                <span>Location:</span><span>Atlanta</span>
                                            </div>
                                        </div>
                                        <div className="owner_info">
                                            <div className="owner_icons">
                                                <span>RC</span>
                                            </div>
                                            <div className="cnt">
                                                <h6>Rich Capital Properties LLC</h6>
                                                <p>Po Box 781, Atlanta, TX 75551</p>
                                            </div>
                                        </div>
                                        <div className="tiles_flex">
                                            <div className="col">
                                                <div className="inner">
                                                    <p>Properties in Portfolio</p>
                                                    <h6>03</h6>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="inner">
                                                    <p>Last Acquisition Date</p>
                                                    <h6>Sep 2022</h6>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="inner">
                                                    <p>Portfolio Assessed Value</p>
                                                    <h6>$203,235</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contacts">
                                        <h3>Contacts</h3>
                                        <div className="contact_info">
                                            <div className="dp">
                                                <img src="/images/cnt.png" alt="" />
                                            </div>
                                            <div className="cnt">
                                                <h5>Valentino Parker</h5>
                                                <p>Chief Executive Officer</p>
                                            </div>
                                        </div>
                                        <div className="devide_line"></div>
                                        <div className="contact_information">
                                            <h4>Contact information</h4>
                                            <div className="contact_info">
                                                <div className="dp">
                                                    <img src="/images/profile.svg" alt="" />
                                                </div>
                                                <div className="cnt">
                                                    <h5>+1 903-324-5832 <span>|</span> Rthomason@slocumisd.org</h5>
                                                    <p>Clements Construction & Realty Co Inc <span>Residential</span></p>
                                                </div>
                                            </div>
                                            <div className="devide_line"></div>
                                            <div className="contact_info">
                                                <div className="dp">
                                                    <img src="/images/profile.svg" alt="" />
                                                </div>
                                                <div className="cnt">
                                                    <h5>+1 903-324-5832 <span>|</span> Rthomason@slocumisd.org</h5>
                                                    <p>Clements Construction & Realty Co Inc <span>Residential</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
            <div className="colR">
                <div className="outer_map">
                    <div className="map">
                        <iframe src="https://rhinorecon.000webhostapp.com/" width="100%" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default PropertyDetails;

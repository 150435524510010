import React, { useEffect , useState} from "react";
function Block({ data }) {
  return (
    <>
        <section className="mini_sec">
            <div className="contain">
                <div className="flex">
                    <div className="colL">
                        <h3>{data.heading}</h3>
                        <p>{data.pera}</p>
                        <p><strong>{data.sub_heading}</strong></p>
                        <div className="flex">
                            {data.block_loop?.map((val,i) => {
                                return(
                                    <div className="col_c" key={i}>
                                        <div className="inner_in">
                                            <div className="sm_icon">
                                            <img src={val.icon} alt={val.title} />
                                            </div>
                                            <div className="cn_tnt">
                                                <h5>{val.title}</h5>
                                                <p>{val.label}</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                );
                            })}
                            
                        </div>
                    </div>
                    <div className="colR">
                        <div className="image">
                            <img src={data.image} alt={data.heading} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Block;

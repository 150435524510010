import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

export default function Nav({ active }) {
    const [show, setShow] = useState(false);
    const[dropShow, setDropShow] = useState(false);
    const[searches, setSearches] = useState(false);
    const ToggleSearches = () => {
        setSearches(!searches);
    }
    const ToggleDrop = () => {
        setDropShow(!dropShow);
    }
    const SubNav = () => {
        setShow(!show);
    };
    return (
        <>
        {/* for desktop navigation */}
            <nav className={active ? "loged_nav active" : "loged_nav"} nav="true" id="nav" >
                <ul className="nav_neg">
                    <li className={searches ? "nav_drop active" : "nav_drop"}>
                        <button onClick={ToggleSearches} className="nav_drop_btn">
                        Saved searches
                        </button>
                        <div className="sub sm_wide">
                            <div className="parts_searches">
                                <p>Multifamily New Owner Leads</p>
                                <button>Building area min: 10k sf</button>
                                <button>Property type: Multifamily</button>
                                <button>Sales: Post 2 years</button>
                                <div className="time">1 month ago</div>
                            </div>
                            <div className="devide_line"></div>
                            <div className="parts_searches">
                                <p>Renovation leads</p>
                                <button>Built upto 2022</button>
                                <button>Renovated upto 2022</button>
                                <div className="time">A week ago</div>
                            </div>
                            <Link to="/saved-searches" className="site_btn">View all</Link>
                        </div>
                    </li>
                    <li className="">
                        <NavLink to="/help" className={({ isActive }) => (isActive ? "active" : "")}>
                        Help Center
                        </NavLink>
                    </li>
                </ul>
            </nav>
            <ul className="log_btns hide_cell_log_btns">
                <li>
                    <NavLink to="/upload-data" className="site_btn blank yellow_blank">
                    Data upload
                    </NavLink>
                </li>
                <li className={dropShow ? "drop dashboard_dp active" : "drop dashboard_dp"}>
                    <NavLink to="#" onClick={ToggleDrop} className="profile_btn drop_btn">
                    CK
                    </NavLink>
                    <div className="sub">
                        <div className="head_user">
                            <div className="user_dp">
                                <img src="/images/user_dp.png" alt="" />
                            </div>
                            <div className="user_name">
                                <h6>Mr.Matt</h6>
                                <p>Buyer</p>
                            </div>
                        </div>
                        <ul className="lst">
                            <li>
                                <span><img src="/images/profile_setting.svg" alt="" /></span>
                                <Link to="/account-settings">Profile Settings</Link>
                            </li>
                            <li>
                                <span><img src="/images/support.svg" alt="" /></span>
                                <Link to="/help">Support</Link>
                            </li>
                            <li>
                                <span><img src="/images/logout.svg" alt="" /></span>
                                <Link to="/">Logout</Link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

            <div className="clearfix"></div>

        {/* end for desktop navigation */}

        {/* mobile_navigation */}
            <nav className="cell_logged_nav" nav="true">
                <ul className="nav_neg">
                    <li className={searches ? "nav_drop active" : "nav_drop"}>
                        <button onClick={ToggleSearches} className="nav_drop_btn">
                        Saved searches
                        </button>
                        <div className="sub sm_wide">
                            <div className="parts_searches">
                                <p>Multifamily New Owner Leads</p>
                                <button>Building area min: 10k sf</button>
                                <button>Property type: Multifamily</button>
                                <button>Sales: Post 2 years</button>
                                <div className="time">1 month ago</div>
                            </div>
                            <div className="devide_line"></div>
                            <div className="parts_searches">
                                <p>Renovation leads</p>
                                <button>Built upto 2022</button>
                                <button>Renovated upto 2022</button>
                                <div className="time">A week ago</div>
                            </div>
                            <Link to="/saved-searches" className="site_btn">View all</Link>
                        </div>
                    </li>
                    <li className="">
                        <NavLink to="/help" className={({ isActive }) => (isActive ? "active" : "")}>
                        Help Center
                        </NavLink>
                    </li>
                </ul>
            </nav>
            <ul className="log_btns cell_log_btns">
                <li>
                    <NavLink to="/upload-data" className="site_btn blank yellow_blank">
                    Data upload
                    </NavLink>
                </li>
                <li className={active ? "drop drop dashboard_dp active" : "drop dashboard_dp"}>
                    <NavLink to="#" onClick={ToggleDrop} className="profile_btn drop_btn">
                    CK
                    </NavLink>
                    <div className="sub mobile_dashboard_dp">
                        <div className="head_user">
                            <div className="user_dp">
                                <img src="/images/user_dp.png" alt="" />
                            </div>
                            <div className="user_name">
                                <h6>Mr.Matt</h6>
                                <p>Buyer</p>
                            </div>
                        </div>
                        <div className="text-center">
                            <Link to="/upload-data" className="site_btn blank yellow_blank">Data Upload</Link>
                        </div>
                        <ul className="lst">
                            <li>
                                <span><img src="/images/profile_setting.svg" alt="" /></span>
                                <Link to="/account-settings">Profile Settings</Link>
                            </li>
                            <li>
                                <span><img src="/images/support.svg" alt="" /></span>
                                <Link to="/support">Support</Link>
                            </li>
                            <li>
                                <span><img src="/images/logout.svg" alt="" /></span>
                                <Link to="/">Logout</Link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

            <div className="clearfix"></div>
            {/* end mobile navigation */}
        
        </>
    )
}

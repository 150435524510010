import React from "react";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import SocialLinks from "./SocialLinks";
import {useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  const location_path = location.pathname;
  const current_page = location_path.split('/')[1];

  return (
    <>
    {(current_page=='login' || current_page=='signup' || current_page=='enterprise-checkout' || current_page=='checkout') ? 
    ""
    : 
    <footer>
        <div className="contain">
          <div className="flex">
            <div className="col">
              <div className="f_logo">
                <Logo />
              </div>
            </div>
            <div className="col">
              <h5>Services</h5>
              <ul className="lst">
                <li>
                  <Link to="/">Roofing</Link>
                </li>
                <li>
                  <Link to="/">Real Estate</Link>
                </li>
                <li>
                  <Link to="/">Pressure Washign</Link>
                </li>
                <li>
                  <Link to="/">Solar Energy</Link>
                </li>
                <li>
                  <Link to="/">Pest Control</Link>
                </li>
                <li>
                  <Link to="/">Landscaping</Link>
                </li>
                <li>
                  <Link to="/">More</Link>
                </li>
              </ul>
              <div className="br" />
              <h5>LEARN THE RHINO WAY</h5>
              <ul className="lst">
                <li>
                  <Link to="/">Watch Tutorials</Link>
                </li>
              </ul>
            </div>
            <div className="col">
              <h5>LIST PROPERTY</h5>
              <ul className="lst">
                <li>
                  <Link to="/">List your roperties</Link>
                </li>
                <li>
                  <Link to="/">Filter sales calls</Link>
                </li>
                <li>
                  <Link to="/">Optimization</Link>
                </li>
              </ul>
              <div className="br" />
              <h5>TERMS &amp; PRIVACY</h5>
              <ul className="lst">
                <li>
                  <Link to="/">Trust &amp; Safety</Link>
                </li>
                <li>
                  <Link to="/">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className="col">
              <h5>ABOUT</h5>
              <ul className="lst">
                <li>
                  <Link to="/">Company</Link>
                </li>
                <li>
                  <Link to="/">How it works</Link>
                </li>
                <li>
                  <Link to="/">Contact</Link>
                </li>
                <li>
                  <Link to="/">Investors</Link>
                </li>
              </ul>
              <div className="br" />
              <h5>RESOURCES</h5>
              <ul className="lst">
                <li>
                  <Link to="/">Blog</Link>
                </li>
                <li>
                  <Link to="/">Guides</Link>
                </li>
                <li>
                  <Link to="/">FAQ</Link>
                </li>
                <li>
                  <Link to="/">Help Center</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copy_right">
          <div className="contain">
            <div className="_inner">
              <p>©2022 RhinoRecon. All rights reserved</p>
              <SocialLinks />
            </div>
          </div>
        </div>
      </footer>
    }
      
    </>
  );
}

export default Footer;

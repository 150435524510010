import React from "react";
import Intro from "./Intro";
import Card from "./Card";

const EnterpriseCheckout = () => {
    const log = {
        card : {
            image:"/images/property.png",
            popLbl:"POPULAR",
            infoLbl:"Also owns",
            infoLnkLbl:"3 other properties",
            infoLnk:"/",
            phone:"+1234567890",
            email:"john@doe.com",
            position:"Owner",
            name:"John Doe",
            btnLbl:"Contact Now",
            btnLnk:"/",
        },
        intro:{
            title:"Enterprise",
            detail:"Fill out a form and submit to the managment."
        },
        right_detail:{
           details:"<h3>Why Rhino Recon?</h3><p>Find more opportunities, connect with owners instantly, and analyze properties with ease.</p><ul><li>Our interactive map will help you find target properties.</li><li>Get the right data, including sales, tax, and debt details.</li><li>Find property owners and talk to the right person in seconds.</li></ul>"
        }
    }
  return (
    <>
      <section className="logon">
        <div className="contain">
            <div className="flex">
                <Intro data = {log.intro}/>
                <div className="colR self_center">
                    <div className="right_pera_details" dangerouslySetInnerHTML={ {__html: log.right_detail.details} } />
                    <div className="br"></div>
                    <Card data = {log.card}/>
                </div>
            </div>
        </div>
        <div className="lg_image">
            <img src="/images/logon.png" alt="" />
        </div>
      </section>
    </>
  );
};

export default EnterpriseCheckout;
import React from "react";

const Search = ({data}) => {
  return (
    <>
      <section className="search_banner">
        <div className="contain">
            <h3>{data.heading}</h3>
            <form action="">
                <div className="form_blk">
                    <input type="text" placeholder="Ask a question" className="input"/>
                    <img src="/images/search.svg" alt="" />
                </div>
            </form>
            <div className="dim_img">
              <img src="/images/rahino.svg" alt="" />
            </div>
        </div>
      </section>
    </>
  );
};

export default Search;

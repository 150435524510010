import React from "react";
import { Link } from "react-router-dom";

const TableData = (props) => {
    const {hideSearch} = props;
    const {data} = props;
  return (
    <>
      <section className={hideSearch ? "table_view_sec filter_show_after" : "table_view_sec"}>
        <div className="contain-fluid">
            <div className="inner_tbl">
                <table>
                    <thead>
                        <tr>
                            <th>Address</th>
                            <th>Last Sale</th>
                            <th>Sales Price</th>
                            <th>Year Built</th>
                            <th>Building Area</th>
                            <th>Units</th>
                            <th>Property Type</th>
                            <th>Contact Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((val,i)=>{
                            return(
                                <tr key={i}>
                                    <td>{val.address}</td>
                                    <td>{val.last_sale}</td>
                                    <td>{val.sale_price}</td>
                                    <td>{val.year_built}</td>
                                    <td>{val.area}</td>
                                    <td>{val.units}</td>
                                    <td>{val.type}</td>
                                    <td>
                                        <ul className="social">
                                            <li>
                                                <Link to={val.call}>
                                                    <img src="/images/call-icon.svg" alt="" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={val.email}>
                                                    <img src="/images/email-icon.svg" alt="" />
                                                </Link>
                                            </li>
                                            
                                        </ul>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="pagination_blk">
                    <div className="colL">
                        <span>1－10 of 57</span>
                    </div>
                    <div className="colR">
                        <div className="pagination_slider">
                            <span>1 of 6</span>
                            <button className="perv_btn"><img src="/images/left_arrow.svg" alt="" /></button>
                            <button className="next_btn"><img src="/images/left_arrow.svg" alt="" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default TableData ;

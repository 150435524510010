import React from "react";
import { Link } from "react-router-dom";

const MembershipManagement = () => {
  return (
    <>
      <div className="setting_blk wide_md">
            <h4>Membership management</h4>
            <div className="mini_text_sm">Contact Sales at  <Link to="tel:942-849-3849">942-849-3849</Link>  or  <Link to="Rhino@sales.com">Rhino@sales.com</Link></div>
            <div className="table_blk">
                <div className="inner_tbl">
                    <table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>License</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><div className="mini_color_text">Carlton Kohler (carlton@eliteexteriorsga.com)</div></td>
                                <td>Reonomy Professional	</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
      </div>
      <div className="pkg_upgrade_blk pro_upgrade_blk">
        <div className="pkg_badge"><img src="/images/green-star.svg" alt="" /><span>PRO</span></div> 
        <div className="cntnt">
            <p>Exports Remaining</p>
            <h4>1000</h4>
            <p><small>Reset on 28 Sep 2022 | 04:59 GMT+5</small></p>
        </div>
        <Link to="/" className="site_btn">Upgrade Now</Link>
      </div>
      {/* <div className="pkg_upgrade_blk simple_upgrade_blk">
        <div className="cntnt text-center">
            <p>Exports Remaining</p>
            <h4>1000</h4>
            <p><small>Reset on 28 Sep 2022 | 04:59 GMT+5</small></p>
        </div>
        <Link to="/" className="site_btn">Upgrade Now</Link>
      </div> */}
    </>
  );
};

export default MembershipManagement
import React from "react";
import { Link } from "react-router-dom";

const Card = ({data}) => {
  return (
    <>
      <div className="property_card">
        <Link to="?" className="image">
            <img src={data.image} alt="" />
        </Link>
        <div className="property_badge">
            <img src="/images/star.svg" alt="" />
            <span>{data.popLbl}</span>
        </div>
        <div className="cntnt">
            <div className="outer_about">
            <div className="abt_lbl">
                <img src="/images/info.svg" alt="" />
                <p>{data.infoLbl} <Link to={data.infoLnk}>{data.infoLnkLbl}</Link></p>
            </div>
            <div className="like_btn">
                <button>
                <img
                    src="/images/heart.svg"
                    alt=""
                />
                </button>
            </div>
            </div>
            <hr />
            <ul className="contact_flex">
            <li>
                <span>
                <img
                    src="/images/Call.svg"
                    alt=""
                />
                </span>
                <Link to="?">{data.phone}</Link>
            </li>
            <li>
                <span>
                <img
                    src="/images/Message.svg"
                    alt=""
                />
                </span>
                <Link to="?">{data.email}</Link>
            </li>
            </ul>
            <div className="owner_card">
            <div className="left_own">
                <p>{data.position}</p>
                <h5>{data.name}</h5>
            </div>
            <Link to className="contact_drop">
                <div className="file_img">
                <img
                    src="/images/document-text.svg"
                    alt=""
                />
                </div>
                <span>{data.btnLbl}</span>
                <img
                src="/images/down_arrow.svg"
                alt=""
                />
            </Link>
            </div>
        </div>
      </div>
    </>
  );
};

export default Card;

import React from "react";
import Form from "./Form";

const Intro = ({data}) => {
  return (
    <>
      <div className="colL">
        <div className="inner">
            <Form data={data}/>
        </div>
      </div>
    </>
  );
};

export default Intro;

import React from "react";
import BlogPage from "./Blog-page";

const Blog = () => {
    const blog = {
        feature_heading:"Featured Articles",
        heading: "All Articles",
        featured_post :{
            id:1,
            image:"/images/blog1.png",
            category:"By Ashley William in Featured",
            title:"How ESG Data is Changing Commercial Real Estate",
            intro:"Cal Inman from ClimateCheck and David Vigilante from Reonomy came together to discuss the increasing importance of environmental data to CRE.",
        },
        all_posts: [
            {
                id:2,
                image:"/images/blog2.png",
                category:"By Ashley William in Entrepreneurship",
                title:"Gain a competitive edge with CRE big data",
                intro:"Avoid burnout, reduce stress, and keep yourself healthy with these practical self-care tips for entrepreneurs."
            },
            {
                id:3,
                image:"/images/blog3.png",
                category:"By Taylor Barbiery in Entrepreneurship",
                title:"Not an expert? Not a problem. Here’s why.",
                intro:"Worried about teaching or leading because you’re not an ‘expert’? Research says that’s an advantage. Learn how to leverage your inexperience for better products."
            },
            {
                id:4,
                image:"/images/blog4.png",
                category:"Announcement",
                title:"Meet AutoManage, the best AI management tools",
                intro:"We’re proud to announce AutoManage 1st AI management tools in this year.  "
            },
            {
                id:5,
                image:"/images/blog5.png",
                category:"Company",
                title:"The Basics of Building a Business",
                intro:"If you’ve ever wanted to start your own business but aren’t sure where to start or feel alone, afraid and overwhelmed"
            },
            {
                id:6,
                image:"/images/blog6.png",
                category:"Platforms",
                title:"Learn to Buy, Grow, and Sell Small Companies",
                intro:"I've bought or sold 10+ small businesses -- here's everything I know about how to do it profitably, with little or no money."
            },
            {
                id:7,
                image:"/images/blog7.png",
                category:"Sales",
                title:"The no-fuss guide to upselling and cross selling",
                intro:"Upselling and cross-selling are easy techniques for increasing the profit of each and every sale."
            },
            {
                id:8,
                image:"/images/blog8.png",
                category:"Insight",
                title:"How to earn more money as a wellness coach",
                intro:"Earning more as a wellness coach doesn't have to be complicated, and with this thorough guide on the six best income"
            },
            {
                id:9,
                image:"/images/blog9.png",
                category:"Announcement",
                title:"Shut down an online course gracefully",
                intro:"Retire your online course without damaging your brand’s reputation or your customers’ trust in you. "
            },
        ]
    }
  return (
    <>
      <BlogPage data={blog}/>
    </>
  );
};

export default Blog;

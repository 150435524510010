import React from "react";

const States = ({data}) => {

  return (
    <>
      <section className="abt_states">
        <div className="contain">
            <div className="flex">
                {data?.map((val , i) => {
                    return(
                        <div className="col" key={i}>
                            <h5>{val.number}</h5>
                            <p>{val.title}</p>
                        </div>
                    );
                })}
            </div>
        </div>
      </section>
    </>
  );
};

export default States;

import React from "react";
import Banner from "./Banner";
import Detail from "./Detail";

const PrivacyPolicy = () => {
    const data = {
        banner:{
            heading:"Privacy policies",
        },
        detail:{
            text:"<h5>Privacy policy</h5><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non quis nisl, ac blandit. Amet non fermentum non egestas id dui, scelerisque eu. Senectus risus nibh quisque sit purus egestas viverra nisl metus. Nulla lectus quis sit elementum. Et sed faucibus tempor aliquet aliquet in sodales diam tempus. Duis sed tempor feugiat tincidunt in sed vel ullamcorper. Imperdiet ut amet lobortis in viverra varius risus pharetra. Arcu, id parturient massa libero quam fringilla fermentum. In aliquam vitae elementum non porttitor est erat amet cras. Rhoncus tellus pulvinar faucibus id. Pharetra sagittis leo massa sit. Nullam sed nunc ullamcorper vitae, ac auctor fringilla mauris. Vitae blandit porttitor viverra consectetur nibh vitae, tincidunt tellus.</p>"
        }
    }
  return (
    <>
      <Banner data={data.banner}/>
      <Detail data={data.detail}/>
    </>
  );
};

export default PrivacyPolicy;

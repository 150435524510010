import React from "react";
import { Link } from "react-router-dom";

const Exports = () => {
  return (
    <>
      <div className="setting_blk cell_min_padding">
            <h4>Exports</h4>
            <div className="table_blk">
                <div className="inner_tbl">
                    <table className="cell_hide_dowload_lbl">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Properties</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Dec 9, 2022</td>
                                <td>Rhino_export.xlsx</td>
                                <td>01</td>
                                <td>Completed</td>
                                <td>
                                    <div className="down_load_lbl">
                                        <span>Download</span>
                                        <img src="/images/download_icon.svg" alt="" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Dec 9, 2022</td>
                                <td>Rhino_export.xlsx</td>
                                <td>01</td>
                                <td>Completed</td>
                                <td>
                                    <div className="down_load_lbl">
                                        <span>Download</span>
                                        <img src="/images/download_icon.svg" alt="" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Dec 9, 2022</td>
                                <td>Rhino_export.xlsx</td>
                                <td>01</td>
                                <td>Completed</td>
                                <td>
                                    <div className="down_load_lbl">
                                        <span>Download</span>
                                        <img src="/images/download_icon.svg" alt="" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
      </div>
    </>
  );
};

export default Exports;
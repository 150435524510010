import React from "react";

const UploadTables = ({data}) => {
  return (
    <>
      <section className="upload_table">
        <div className="contain">
           <div className="table_blk">
            <div className="header_tbl flex">
                <h5>{data.heading}</h5>
                <div className="btn_blk">
                    <button className="site_btn">{data.button_lbl}</button>
                </div>
            </div>
            <div className="inner_tbl">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Records</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.tbl?.map((val,i)=>{
                            return(
                                <tr key={i}>
                                    <td>{val.date}</td>
                                    <td>{val.status}</td>
                                    <td>{val.records}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
           </div>
        </div>
      </section>
    </>
  );
};

export default UploadTables;

import React ,{ useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Personal = ({page, setPage, data}) => {
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }
  return (
    <>
          <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, times: [2, 3, 2] }}
          exit={{ opacity: 0 }}
          >
          <fieldset>
              <div className="step_from">Step 1 of 2</div>
              <div className="option_lbl">
                  <ul>
                      <li className="active">Personal Info</li>
                      <li>Company</li>
                  </ul>
              </div>
              <h4>{data.title1}</h4>
              <p>{data.detail1}</p>
              <div className="blk_form">
                <h6>Name</h6>
                <div className="form_blk">
                    <input
                        type="text"
                         
                        className="input"
                        onChange={handleChange}
                        placeholder="Full name"
                    />
                </div>
              </div>
              <div className="blk_form">
                <h6>Email</h6>
                <div className="form_blk">
                    <input
                        type="text"
                         
                        className="input"
                        onChange={handleChange}
                        placeholder="hi@example.com"
                    />
                </div>
              </div>
              <div className="blk_form">
                <h6>Password</h6>
                  <div className="form_blk pass_blk">
                      <input
                      type="password"
                      name="password"
                      id="password"
                       
                      onChange={handleChange}
                      className="input"
                      placeholder="Enter password"
                      />
                      <i className="icon-eye" />
                  </div>
              </div>
              <div className="blk_form">
                <h6>Confirm Password</h6>
                  <div className="form_blk pass_blk">
                      <input
                      type="password"
                      name="r_password"
                      id="r_password"
                       
                      onChange={handleChange}
                      className="input"
                      placeholder="Enter password"
                      />
                      <i className="icon-eye" />
                  </div>
              </div>
              <div className="small_txt"><small>Must be at least 12 characters and combination of small and capital letters, symbols, and numbers.</small></div>
              
              <div className="btn_blk">
                <button className="site_btn nextBtn" type="button" onClick={() => {
                      setPage(page + 1);
                  }}>
                  Next
                </button>
                <button className="site_btn blank" type="button">
                  <img src="/images/google.svg" alt="" /> Sign up with Google
                </button>
              </div>
              <div className="dont_acc">
                  <p>Already have an account? <Link to="/login">Login</Link></p>
              </div>
          </fieldset>
        </motion.div>
    </>
  );
};

export default Personal;

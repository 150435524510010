import React from "react";

const PricingCard = ({data}) => {
    
  return (
    <>
      <div className="col_p">
        <div className="inner">
        <div className="head_pricing">
            <h5>{data.type}</h5>
            <p>{data.lbl}</p>
        </div>
        <div className="price_body">
            <div dangerouslySetInnerHTML={ {__html: data.listing} } />
        </div>
        <div className="price_footer">
            <h4>{data.price}</h4>
        </div>
        </div>
    </div>
    </>
  );
};

export default PricingCard;

import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const Video = ({data}) => {

  return (
    <>
      <section className="abt_video">
        <div className="contain">
            <div className="flex">
                <div className="colL">
                    <h2>{data.heading}</h2>
                    <p>{data.detail}</p>
                    <Link to="/">{data.lnk_lbl}</Link>
                </div>
                <div className="colR">
                    <div className="inner">
                      <ReactPlayer
                        url="https://vimeo.com/243556536"
                        width="100%"
                        height="500px"
                        playing
                        playIcon={<button className="play_btn"><img src="/images/vid_play.svg" alt="" /></button>}
                        light={data.thumbnail}
                      />
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default Video;

import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

export default function Navigation({ active }) {
    const [show, setShow] = useState(false);
    const [resources, setResources] = useState(false);
    const SubNav = () => {
        setShow(!show);
    };
    const ToggleResources = (e) => {
        setResources(!resources);
        e.preventDefault();
    };
    return (
        <>
            <nav className={active ? "active" : ""} nav="true" id="nav" >
                <ul className="nav_neg">
                    <li className="">
                        <NavLink to="/pricing" className={({ isActive }) => (isActive ? "active" : "")}>
                        Pricing
                        </NavLink>
                    </li>
                    <li className="drop">
                        <NavLink to="#" onClick={ToggleResources} className={({ isActive }) => (isActive ? "" : "")}>
                        Resources
                        </NavLink>
                        <div className={resources ? "sub active" : "sub"}>
                            <div className="flex_row row">
                                <div className="col-md-12 resource_small_flex_tite">
                                    <Link to="#" onClick={ToggleResources}>
                                        <img src="/images/back_angle.svg" alt="" />
                                        <span>Resources</span>
                                    </Link>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <div className="nav_sub_blk">
                                            <div className="ico">
                                                <img src="/images/blog_icon.svg" alt="" />
                                            </div>
                                            <div className="txt">
                                                <h4 className="blue_color">Blog  <img src="/images/blog_arrow.svg" alt="" /></h4>
                                                <p>
                                                Our latest news, Featured Articles, Insights and how to guided for motivated and curious professional
                                                </p>
                                                <Link to="/blogs">
                                                    <span>Visit Blog</span>
                                                    <img src="/images/arrow_mini.svg" alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <div className="nav_sub_blk">
                                            <div className="ico">
                                                <img src="/images/help_icon.svg" alt="" />
                                            </div>
                                            <div className="txt">
                                                <h4 className="green_color">Help center <img src="/images/help_arrow.svg" alt="" /></h4>
                                                <p>
                                                Our latest news, Featured Articles, Insights and how to guided for motivated and curious professional
                                                </p>
                                                <Link to="/help">
                                                    <span>Visit Help Center</span>
                                                    <img src="/images/arrow_mini.svg" alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <div className="nav_sub_blk">
                                            <div className="ico">
                                                <img src="/images/support_icon.svg" alt="" />
                                            </div>
                                            <div className="txt">
                                                <h4 className="zink_color">Support <img src="/images/support_arrow.svg" alt="" /></h4>
                                                <p>
                                                Our latest news, Featured Articles, Insights and how to guided for motivated and curious professional
                                                </p>
                                                <Link to="/support">
                                                    <span>Visit Support</span>
                                                    <img src="/images/arrow_mini.svg" alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    {/* <li className="">
                        <NavLink to="/blogs" className={({ isActive }) => (isActive ? "active" : "")}>
                        Blog
                        </NavLink>
                    </li> */}
                    <li className="">
                        <NavLink to="/about" className={({ isActive }) => (isActive ? "active" : "")}>
                        About
                        </NavLink>
                    </li>
                    {/* <li className="drop">
                        <NavLink to="javascript:void(0)" className="drop_btn">
                        Discovery Center
                        </NavLink>
                        <div className="drop_cnt">
                            <ul className="drop_lst">
                                <li>
                                <NavLink to="/">
                                Discovery Center
                                </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li> */}
                    
                </ul>
                {/* <ul className="responsive_logs">
                    <li>
                        <NavLink to="/login" className="site_btn blank">
                        Login
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/signup" className="site_btn">
                        Free Trial
                        </NavLink>
                    </li>
                </ul> */}
            </nav>
            <ul className="log_btns">
                <li>
                    <NavLink to="/login" className="site_btn blank">
                    Login
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/signup" className="site_btn">
                    Free Trial
                    </NavLink>
                </li>
            </ul>
            <div className="clearfix"></div>


        </>
    )
}

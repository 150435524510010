import React from "react";
import ExportInfo from "./Export-info";

const Export = () => {
  return (
    <>
        <ExportInfo />
    </>
  );
};

export default Export;

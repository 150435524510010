import React from "react";
import Packages from "./Packages";
import MonthlyPlan from "./MonthlyPlan";
import AnnualPlan from "./AnnualPlan";
import Faqs from "./Faqs";

const Pricing = () => {
    const mnthly_pkgs = [
        {
            id:1,
            type:"Mid End",
            lbl:"The best gift full of surprises",
            listing:"<ul><li>1-10 Accounts</li><li>48 hours trial</li><li>Basic donor data</li><li>Team fudraising</li></ul>",
            duration:"CANCEL ANYTIME",
            price:"$ 499/mo",
            btnLbl:"Free Trial",
            btnLnk:"/checkout",
            mini_lbl:"Free 3 Days Trial"
        },
        {
            id:2,
            type:"High End",
            lbl:"For the Ultimate Eco-warrior",
            listing:"<ul><li>11-50 Accounts</li><li>48 hours trial</li><li>Basic donor data</li><li>Team fudraising</li><li>Unlimited fundraising campaigns</li><li>Unlimited fundraising events</li></ul>",
            duration:"STARTING FROM",
            price:"$ 799/mo",
            btnLbl:"Free Trial",
            btnLnk:"/checkout",
            mini_lbl:"Free 3 Days Trial"
        },
        {
            id:3,
            type:"Enterprize",
            lbl:"Let’s Talk",
            listing:"<ul><li>51+ Accounts</li><li>48 hours trial</li><li>Basic donor data</li><li>Team fudraising</li><li>Unlimited fundraising campaigns</li><li>Unlimited fundraising events</li><li>Comrehensive donor data</li></ul>",
            duration:"STARTING FROM",
            price:"$ 1000/mo",
            btnLbl:"Contact Us",
            btnLnk:"/enterprise-checkout"
        }
    ]

    const annual_pkgs = [
        {
            id:1,
            type:"Mid End",
            lbl:"The best gift full of surprises",
            listing:"<ul><li>1-10 Accounts</li><li>48 hours trial</li><li>Basic donor data</li><li>Team fudraising</li></ul>",
            duration:"CANCEL ANYTIME",
            price:"$ 899/year",
            btnLbl:"Free Trial",
            btnLnk:"/checkout",
            mini_lbl:"Free 3 Days Trial"
        },
        {
            id:2,
            type:"High End",
            lbl:"For the Ultimate Eco-warrior",
            listing:"<ul><li>11-50 Accounts</li><li>48 hours trial</li><li>Basic donor data</li><li>Team fudraising</li><li>Unlimited fundraising campaigns</li><li>Unlimited fundraising events</li></ul>",
            duration:"STARTING FROM",
            price:"$ 899/year",
            btnLbl:"Free Trial",
            btnLnk:"/checkout",
            mini_lbl:"Free 3 Days Trial"
        },
        {
            id:3,
            type:"Enterprize",
            lbl:"Let’s Talk",
            listing:"<ul><li>51+ Accounts</li><li>48 hours trial</li><li>Basic donor data</li><li>Team fudraising</li><li>Unlimited fundraising campaigns</li><li>Unlimited fundraising events</li><li>Comrehensive donor data</li></ul>",
            duration:"STARTING FROM",
            price:"$ 9900/year",
            btnLbl:"Contact Us",
            btnLnk:"/enterprise-checkout"
        }
    ]
    const data = {
        title:"Free 3 days trial",
        mini_title:"(Cancel anytime)",
        detail:"Try all available features today with our 3 days trial. No credit card required.",
        tabs:[
            {
                id:1,
                tabTitle:"Monthly Plan",
                content:<MonthlyPlan data={mnthly_pkgs}/>,
            },
            {
                id:2,
                tabTitle:"Annual Plan",
                sm_lbl:"Save 15%",
                content:<AnnualPlan data={annual_pkgs}/>,
            }
        ],
    }
    const faq = {
       heading:"Frequently Asked Questions",
       rows: [
            {
                id: "1",
                title: "What are Monthly Tracked Users (MTUs)?",
                content: "We don't stop collecting your data. Once you've finished using your prepaid amount of monthly tracked users you'll be charged our à la carte rate (20% above our normal plan rates) for each additional user tracked that month.",
            },
            {
                id: "2",
                title: "What if I go over my prepaid monthly?",
                content: "We don't stop collecting your data. Once you've finished using your prepaid amount of monthly tracked users you'll be charged our à la carte rate (20% above our normal plan rates) for each additional user tracked that month.",
            },
            {
                id: "3",
                title: "What's the difference between monthly and annual plans?",
                content: "We don't stop collecting your data. Once you've finished using your prepaid amount of monthly tracked users you'll be charged our à la carte rate (20% above our normal plan rates) for each additional user tracked that month.",
            },
            {
                id: "4",
                title: "What do you mean by data history limits? Are you deleting my data?",
                content: "We don't stop collecting your data. Once you've finished using your prepaid amount of monthly tracked users you'll be charged our à la carte rate (20% above our normal plan rates) for each additional user tracked that month.",
            },
            {
                id: "5",
                title: "What are Monthly Tracked Users (MTUs)?",
                content: "We don't stop collecting your data. Once you've finished using your prepaid amount of monthly tracked users you'll be charged our à la carte rate (20% above our normal plan rates) for each additional user tracked that month.",
            },
        ]
    }
  return (
    <>
      <Packages data={data}/>
      <Faqs data={faq}/>
    </>
  );
};

export default Pricing;

import React from "react";
import Faq from 'react-faq-component'; 

const Faqs = ({data}) => {
  return (
    <>
      <section className="faq">
        <div className="contain">
            <div className="text-center">
                <h4>{data.heading}</h4>
            </div>
            <Faq
                data={data}
            />
        </div>
      </section>
    </>
  );
};

export default Faqs;

import React from "react";
import Intro from "./Intro";
import PricingCard from "./Pricing-card";

const Checkout = () => {
    const log = {
        intro:{
            title:"Mid End",
            privacy_text:"<h6>Terms & condition</h6><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque risus sapien enim est tincidunt aliquam. Magna id neque tincidunt elementum mauris gravida est. Imperdiet neque, augue non mauris massa ultrices pharetra viverra.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque risus sapien enim est tincidunt aliquam. Magna id neque tincidunt elementum mauris gravida est. Imperdiet neque, augue non mauris massa ultrices pharetra viverra.</p>",
            detail:"Full access to property data and The best gift full of surprises, Start your 3-day free trail."
        },
        right_detail:{
          type:"Mid End",
          lbl:"The best gift full of surprises",
          listing:"<ul><li>1-10 Accounts</li><li>48 hours trial</li><li>Basic donor data</li><li>Team fudraising</li></ul>",
          price:"€ 899/year",
        }
    }
  return (
    <>
      <section className="logon">
        <div className="contain">
            <div className="flex">
                <Intro data = {log.intro}/>
                <div className="colR self_center pricing_check_out">
                    <PricingCard data={log.right_detail}/>
                </div>
            </div>
        </div>
        <div className="lg_image">
            <img src="/images/logon.png" alt="" />
        </div>
      </section>
    </>
  );
};

export default Checkout;
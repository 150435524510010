import React, { useState, useEffect } from "react";
import Logo from "./Logo";
import Navigation from "./Navigation";
import Nav from "./Nav";
import {useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  const location_path = location.pathname;
  const current_page = location_path.split('/')[1];

  const [toggle, setToggle] = useState(false);
  const ToggleActive = () => {
      setToggle(!toggle);
  };
  return (
      <>
      {(current_page=='login' || current_page=='signup' || current_page=='enterprise-checkout' || current_page=='checkout') ? 
        <header className="ease">
            <div className="contain-fluid">
                <Logo />
            </div>
        </header>
      : 
        <header className="ease">
            <div className="contain-fluid">
                <Logo />
                <div className={!toggle ? "toggle" : "toggle active"} onClick={() => ToggleActive(!toggle)}><span></span></div>
                {(current_page=='' || current_page=='about' || current_page=='pricing' || current_page=='blogs' || current_page=='blog-detail') ? 
                <Navigation active={toggle} />
                :
                <Nav active={toggle}/>
                }
                
            </div>
        </header>
      }
      
      </>
  )
}
export default Header;

import React, { useEffect , useState} from "react";
function Cta({ data }) {
  return (
    <>
       <section className="cta_sec">
            <div className="contain">
                <div className="cntnt">
                    <h5>{data.sub_heading}</h5>
                    <h3>{data.heading}</h3>
                    <p>{data.pera}</p>
                    <form action="">
                        <div className="input_relative">
                            <input type="text" className="input" placeholder="Enter your email address" />
                            <button className="site_btn" type="submit">Submit</button>
                        </div>
                    </form>
                    <div
                    dangerouslySetInnerHTML={{__html: data.mini_label}}
                    />
                </div>
            </div>
        </section>
    </>
  );
}

export default Cta;

import React from "react";
import Faqs from "../pricing/Faqs";
import Banner from "./Banner";
import SocialInfo from "./Social-info";
const Support = () => {
    const data = {
        banner:{
            heading:"Rhino Recon Support",
            pera:"Hi there, how can we help you?"
        },
        social_info : [
            {
                id:"1",
                title:"Help Center",
                image:"/images/support1.svg",
                text:"Access video tutorials, how-to guides, and documentation.",
                link_title:"Visit the Help center",
                link:"/"
            },
            {
                id:"2",
                title:"Chat with us",
                image:"/images/support2.svg",
                text:"Want to start a conversation with us right here?",
                link_title:"Send a message",
                link:"/"
            },
            {
                id:"3",
                title:"Send us an email",
                image:"/images/support3.svg",
                text:"Tell us what you need help with. The more we know, the better we can help you.",
                link_title:"Email us",
                link:"/"
            },
            {
                id:"4",
                title:"Get a Call back",
                image:"/images/support4.svg",
                text:"Have a quick question about your account? Call us at (1123) 447-8098 or schedule a callback.",
                link_title:"schedule a callback",
                link:"/"
            },
        ]
    }
    const faq = {
       heading:"Frequently Asked Questions",
       rows: [
            {
                id: "1",
                title: "What are Monthly Tracked Users (MTUs)?",
                content: "We don't stop collecting your data. Once you've finished using your prepaid amount of monthly tracked users you'll be charged our à la carte rate (20% above our normal plan rates) for each additional user tracked that month.",
            },
            {
                id: "2",
                title: "What if I go over my prepaid monthly?",
                content: "We don't stop collecting your data. Once you've finished using your prepaid amount of monthly tracked users you'll be charged our à la carte rate (20% above our normal plan rates) for each additional user tracked that month.",
            },
            {
                id: "3",
                title: "What's the difference between monthly and annual plans?",
                content: "We don't stop collecting your data. Once you've finished using your prepaid amount of monthly tracked users you'll be charged our à la carte rate (20% above our normal plan rates) for each additional user tracked that month.",
            },
            {
                id: "4",
                title: "What do you mean by data history limits? Are you deleting my data?",
                content: "We don't stop collecting your data. Once you've finished using your prepaid amount of monthly tracked users you'll be charged our à la carte rate (20% above our normal plan rates) for each additional user tracked that month.",
            },
            {
                id: "5",
                title: "What are Monthly Tracked Users (MTUs)?",
                content: "We don't stop collecting your data. Once you've finished using your prepaid amount of monthly tracked users you'll be charged our à la carte rate (20% above our normal plan rates) for each additional user tracked that month.",
            },
        ]
    }

  return (
    <>
      <Banner data={data.banner}/>
      <SocialInfo data={data.social_info}/>
      <Faqs data={faq}/>
    </>
  );
};

export default Support;

import React from "react";

const Banner = ({data}) => {
  return (
    <>
      <section className="data_banner blank_banner">
        <div className="contain">
            <div className="text-center">
                <h3>{data.heading}</h3>
                <p>{data.pera}</p>
            </div>
        </div>
      </section>
    </>
  );
};

export default Banner;

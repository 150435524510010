import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Company = ({page, setPage, data}) => {
  
  return (
    <>
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, times: [2, 3, 2] }}
        exit={{ opacity: 0 }}
        >
            <fieldset>
                <div className="step_from">Step 2 of 2</div>
                <div className="option_lbl">
                    <ul>
                        <li className="active">Personal Info</li>
                        <li className="active">Company</li>
                    </ul>
                </div>
                <h4>{data.title2}</h4>
                <p>{data.detail2}</p>
                <div className="blk_form">
                    <h6>Team size</h6>
                    <div class="form_blk">
                        <select name="" id="" className="input">
                            <option value="">10-15</option>
                            <option value="">15-25</option>
                            <option value="">25-45</option>
                        </select>
                    </div>
                </div>
                <div className="blk_form">
                    <h6>Industry</h6>
                    <div class="form_blk">
                        <select name="" id="" className="input">
                            <option value="">Housing</option>
                            <option value="">Housing</option>
                            <option value="">Housing</option>
                        </select>
                    </div>
                </div>
                <div className="blk_form">
                    <h6>Primary interest</h6>
                    <div class="form_blk">
                        <select name="" id="" className="input">
                            <option value="">Interest1</option>
                            <option value="">Interest2</option>
                            <option value="">Interest3</option>
                        </select>
                    </div>
                </div>
                <div className="btn_blk">
                <button className="site_btn" type="button" onClick={() => {
                        setPage(page + 1);
                    }}>
                Sign up
                </button>
                <button className="site_btn blank" type="button">
                    <img src="/images/google.svg" alt="" /> Sign up with Google
                </button>
                </div>
                <div className="dont_acc">
                    <p>Already have an account? <Link to="/login">Login</Link></p>
                </div>
            </fieldset>
        </motion.div>
    </>
  );
};

export default Company;

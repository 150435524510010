import React from "react";
import Card from "./Card";
import Intro from "./Intro";

const Login = () => {
    const log = {
        card : {
            image:"/images/property.png",
            popLbl:"POPULAR",
            infoLbl:"Also owns",
            infoLnkLbl:"3 other properties",
            infoLnk:"/",
            phone:"+1234567890",
            email:"john@doe.com",
            position:"Owner",
            name:"John Doe",
            btnLbl:"Contact Now",
            btnLnk:"/",
        },
        intro:{
            title:"Welcome back, Rhino!",
            detail:"You can either be a Rhino or a Cow in business. Cows wait for business to 'roll in'. Rhinos go out and GET IT. Let's get you some sales today."
        }
    }
  return (
    <>
      <section className="logon">
        <div className="contain">
            <div className="flex">
                <Intro data = {log.intro}/>
                <div className="colR">
                    <Card data = {log.card}/>
                </div>
            </div>
        </div>
        <div className="lg_image">
            <img src="/images/logon.png" alt="" />
        </div>
      </section>
    </>
  );
};

export default Login;

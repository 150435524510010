import React from "react";
import { Link } from "react-router-dom";

const Questions = ({data}) => {
  return (
    <>
      <section className="question_sec">
        <div className="contain">
            <div className="flex">
                {data?.map((val,i)=>{
                    return(
                        <div className="col" key={i}>
                            <h4>{val.title}</h4>
                            <ul>
                                {val.questions?.map((question,i)=>{
                                    return(
                                        <li>
                                            <Link to={question.lnk}>{question.q_s}</Link>
                                        </li>
                                    );
                                })}
                            </ul>
                            <Link to="/">{val.lnk_lbl}</Link>
                        </div>
                    );
                })}
            </div>
        </div>
      </section>
    </>
  );
};

export default Questions;

import React from "react";

const Banner = ({data}) => {
  return (
    <>
      <section className="data_banner blank_banner">
          <div className="dim_img">
            <img src="/images/half_rahino.svg" alt="" />
          </div>
        <div className="contain">
            <h3>{data.heading}</h3>
        </div>
      </section>
    </>
  );
};

export default Banner;

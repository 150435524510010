import React from "react";

const Banner = ({data}) => {
  return (
    <>
      <section className="data_banner">
          <div className="dim_img">
            <img src="/images/half_rahino.svg" alt="" />
          </div>
        <div className="contain">
            <div className="upload_icon">
                <img src="/images/upload.svg" alt="" />
            </div>
            <h3>{data.heading}</h3>
            <p>{data.text}</p>
        </div>
      </section>
    </>
  );
};

export default Banner;

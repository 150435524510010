import React ,{ useState } from "react";
import { Link } from "react-router-dom";
const Form = () => {
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    alert(inputs);
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
            <div className="blk_form">
              <h6>Name</h6>
              <div className="form_blk">
                  <input
                      type="text"
                      className="input"
                      onChange={handleChange}
                      placeholder="Full name"
                  />
              </div>
            </div>
            <div className="blk_form">
              <h6>Email</h6>
              <div className="form_blk">
                  <input
                      type="text"
                      className="input"
                      onChange={handleChange}
                      placeholder="hi@example.com"
                  />
              </div>
            </div>
            <div className="blk_form">
              <h6>Phone Number</h6>
              <div className="form_blk">
                  <input
                      type="text"
                      className="input"
                      onChange={handleChange}
                      placeholder="+1234567890"
                  />
              </div>
            </div>
            <div className="blk_form">
              <h6>Company</h6>
              <div className="form_blk">
                  <input
                      type="text"
                      className="input"
                      onChange={handleChange}
                      placeholder="Company name"
                  />
              </div>
            </div>
            <div className="blk_form">
              <h6>Job title</h6>
              <div className="form_blk">
                  <input
                      type="text"
                      className="input"
                      onChange={handleChange}
                      placeholder="Job title"
                  />
              </div>
            </div>
            <div className="blk_form">
                <h6>Your indutry</h6>
                <div class="form_blk">
                    <select name="" id="" className="input">
                        <option value="">Your indutry</option>
                        <option value="">Your indutry</option>
                        <option value="">Your indutry</option>
                    </select>
                </div>
            </div>
            <div className="blk_form">
                <h6>Your focus</h6>
                <div class="form_blk">
                    <select name="" id="" className="input">
                        <option value="">Your focus</option>
                        <option value="">Your focus</option>
                        <option value="">Your focus</option>
                    </select>
                </div>
            </div>
            <div className="blk_form">
                <h6>Your Company size</h6>
                <div class="form_blk">
                    <select name="" id="" className="input">
                        <option value="">Your Company size</option>
                        <option value="">Your Company size</option>
                        <option value="">Your Company size</option>
                    </select>
                </div>
            </div>
            <div className="btn_blk">
              <button className="site_btn" type="submit">
              Request a demo!
              </button>
            </div>
            
      </form>
    </>
  );
};

export default Form;

import React , { useState } from "react";
import Company from "./Company";
import Personal from "./Personal";
import Authentication from "./Authentication";

const Form = ({data}) => {
  const [page, setPage] = useState(0);
  const componentList = [
    <Personal 
    page={page}
    setPage={setPage}
    data={data}
    />,
    <Company 
    page={page}
    setPage={setPage}
    data={data}
    />,
    <Authentication 
    page={page}
    setPage={setPage}
    data={data}
    />,
    
  ];
  const handleSubmit = (event) => {
    event.preventDefault();
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        {componentList[page]}
      </form>
    </>
  );
};

export default Form;

import React,{useState} from "react";
import { Link } from "react-router-dom";

const MyAccount = () => {
    const[changePasswordPopup , setChangePasswordPopup] = useState(false);
    const ChangePassword = () => {
        setChangePasswordPopup(!changePasswordPopup);
    }
  return (
    <>
      <div className="setting_blk wide_sm">
            <h4>My Account</h4>
            <form action="">
                <div className="from_row row">
                    <div className="col-sm-12">
                        <div className="blk_form">
                            <h6>Email</h6>
                            <div className="form_blk email_verify">
                                <input
                                type="text"
                                name="email"
                                defaultValue="francis@gmail.com"
                                className="input"
                                />
                                <div className="verify_lbl">Verify</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="blk_form">
                            <h6>Password</h6>
                            <div className="form_blk">
                                <Link to="#" className="input pass_lnk" onClick={ChangePassword}>Change Password</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="blk_form">
                            <div className="switch_flex">
                                <div className="cntnt">
                                    <h6>Enable 2-steps verification</h6>
                                    <div className="dim_lbl">Make your account extra secure. Along with your password, you’ll need to enter a code that we text to your phone each time you sign in.</div>
                                </div>
                                <div className="switch">
                                    <input type="checkbox" name="part" id="part-time" />
                                    <em></em>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divide"></div>
                <div className="link_accnt">
                    <h5 className="sub_acc_heading">Linked Accounts</h5>
                    <div className="dim_lbl1">We use this to let you sign in easily.</div>
                    <div className="lnk_acc_flex">
                        <div className="social_img">
                            <img src="/images/google.svg" alt="" />
                        </div>
                        <h6>Sign in with Google</h6>
                        <div className="btn_blk">
                            <button className="site_btn blank yellow_blank">Remove</button>
                        </div>
                    </div>
                </div>
                <div className="divide"></div>
                <div className="link_accnt">
                    
                    <div className="lnk_del_flex">
                        <div className="cntnt">
                            <h5 className="sub_acc_heading">Delete Account</h5>
                            <div className="dim_lbl1">Delete your account and all the data</div>
                        </div>
                        <div className="btn_blk">
                            <button className="site_btn blank red_blank">Delete Account</button>
                        </div>
                    </div>
                </div>
                <div className="divide"></div>
                <div className="btn_blk text-right cell_wide_full">
                    <button type="submit" className="site_btn">Save changes</button>
                </div>
            </form>
      </div>
      <div class={changePasswordPopup ? "popup sm popup_password_change active" : "popup sm popup_password_change"}>
            <div className="table_dv">
                <div className="table_cell">
                    <div className="contain">
                        <div className="_inner">
                            <button
                            type="button"
                            className="x_btn"
                            onClick={ChangePassword}
                            ></button>
                            <form action="">
                                <div className="from_row row">
                                    <div className="col-sm-12">
                                        <div className="blk_form">
                                            <h6>Current Password</h6>
                                            <div className="form_blk">
                                                <input type="text" className="input"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="blk_form">
                                            <h6>New Password</h6>
                                            <div className="form_blk">
                                                <input type="text" className="input"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="blk_form">
                                            <h6>Confirm Password</h6>
                                            <div className="form_blk">
                                                <input type="text" className="input"/>
                                                <p className="small_text_popup"><small>Password must be at least 12 characters and combination of small and capital letters, symbols, and numbers.</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn_blk text-center">
                                    <button className="site_btn">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </>
  );
};

export default MyAccount;
import React from "react";
import Search from "./Search";
import Questions from "./Questions";

const Help = () => {
    const search = {
        banner:{
            heading:"How can we help?"
        },
        grid:[
            {
                id:1,
                title:"Getting Started",
                lnk_lbl:"View All",
                questions:[
                    {
                        id:1,
                        q_s:"Why do I need to verify my identity?",
                        lnk:"/help-result"
                    },
                    {
                        id:2,
                        q_s:"Downgrading my plan",
                        lnk:"/help-result"
                    },
                    {
                        id:3,
                        q_s:"With money from friends and relatives",
                        lnk:"/help-result"
                    },
                    {
                        id:4,
                        q_s:"Using a concierge",
                        lnk:"/help-result"
                    }
                ]
            },
            {
                id:2,
                title:"Transaction issues",
                lnk_lbl:"View All",
                questions:[
                    {
                        id:1,
                        q_s:"Why has my deposit failed?",
                        lnk:"/help-result"
                    },
                    {
                        id:2,
                        q_s:"Why is my deposit still pending?",
                        lnk:"/help-result"
                    },
                    {
                        id:3,
                        q_s:"Why has my deposit been reverted?",
                        lnk:"/help-result"
                    },
                    {
                        id:4,
                        q_s:"Why was I charged a fee for my deposit?",
                        lnk:"/help-result"
                    }
                ]
            },
            {
                id:3,
                title:"Profile",
                lnk_lbl:"View All",
                questions:[
                    {
                        id:1,
                        q_s:"Are there any deposit limits?",
                        lnk:"/help-result"
                    },
                    {
                        id:2,
                        q_s:"I need to verify my source of funds",
                        lnk:"/help-result"
                    },
                    {
                        id:3,
                        q_s:"I’m unable to verify my identity",
                        lnk:"/help-result"
                    },
                    {
                        id:4,
                        q_s:"I want to close my Revolut account",
                        lnk:"/help-result"
                    }
                ]
            }
        ]
    }
  return (
    <>
      <Search data={search.banner}/>
      <Questions data={search.grid}/>
    </>
  );
};

export default Help;

import React from "react";
import { Link } from "react-router-dom";

function Logo() {
  return (
    <>
      <div className="logo">
        <Link to="/">
          <img src="/images/new_logo1.png" alt="" />
        </Link>
      </div>
    </>
  );
}

export default Logo;

import React from "react";
import Banner from "./Banner";
// import TabContent from "./Tab-content";
import Intro from "./Intro";
import RasidentialTabs from "./Residential-tabs";
import CommercialTabs from "./Commercial-tabs";
import Block from "./Block";
import Cta from "./Cta";

const Home = () => {
  // const calendar = {
  //   image:"images/calendar.svg",
  // }
  const residential_tabs = {
    button_link:"/",
    image:"/images/type_sec.svg",
    play_img:"/images/play.svg",
    play_title:"Virtual builidng inspection",
    play_txt:"View the property in detail",
    home_img:"/images/home.svg",
    home_title:"Be more informed",
    home_txt:"Contact your prospect with confidence.",
    title:"Tired of door-knocking?",
    detail:"Spend your time more wisely by setting up appointments instead of driving around all day.",
    button_label:"Free Trial ",
  }
  const commercial_tabs = {
    button_link:"/",
    image:"/images/commercial.svg",
    play_img:"/images/play.svg",
    play_title:"Virtual builidng inspection",
    play_txt:"View the property in detail",
    home_img:"/images/home.svg",
    home_title:"Be more informed",
    home_txt:"Contact your prospect with confidence.",
    title:"Access property records for commercial properties",
    detail:"Identify target property owners with contact information",
    button_label:"Free Trial ",
  }
  const home = {
    banner: {
        image: "/images/Macbook.svg",
        heading: "Need more sales?",
        text: "Sell smarter not harder by gaining full access to property data and decision maker contact info.",
        
        stats : [
          {
            id:1,
            number :"73%",
            title : "more leads"
          },
          {
            id:2,
            number:"6M+",
            title: "property owners"
          }
        ],
        // tabs:[
        //   {
        //     id:1,
        //     tabTitle:"Discover",
        //     content:<TabContent data={calendar.image}/>,
        //   },
        //   {
        //     id:2,
        //     tabTitle:"Search",
        //     content:<TabContent data={calendar.image}/>,
        //   },
        //   {
        //     id:3,
        //     tabTitle:"Outreach",
        //     content:<TabContent data={calendar.image}/>,
        //   },
        // ],
        
    },
    intro : {
      tabs:[
        {
          id:1,
          tabTitle:"Residential",
          content:<RasidentialTabs data={residential_tabs}/>
        },
        {
          id:2,
          tabTitle:"Commercial",
          content:<CommercialTabs data={commercial_tabs}/>
        }
      ]
    },
    block : {
      image:"/images/Illustration.svg",
      heading:"Less fishing, more Catching!",
      pera:"Spend less time searching for deals and more time closing them, with our software that is so easy to use your grandma could do it.",
      sub_headin:"Three powerful tools at the click of a button",
      block_loop:[
        {
          id:1,
          icon:"/images/icon1.svg",
          title:"Data",
          label:"The web's most Accurate Data"
        },
        {
          id:2,
          icon:"/images/icon2.svg",
          title:"Map",
          label:"Artificial Intelligence powered Map"
        },
        {
          id:3,
          icon:"/images/icon3.svg",
          title:"Contact",
          label:"Decision Maker Contact Info"
        },
      ]
    },
    cta : {
      sub_heading:"No Spam Promise",
      heading:"Are you a landlord?",
      pera:"Discover ways to increase your propery’s value and get listed. No Spam.",
      mini_label:"<p><small>Join <em>10,000+</em> other landlords in the RhinoRecon community.</small></p>",
    }
  };

  return (
    <>
      <Banner data={home.banner} />
      <Intro data={home.intro}/>
      <Block data={home.block}/>
      <Cta data={home.cta}/>
    </>
  );
};

export default Home;

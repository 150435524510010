import React from "react";

const Intro = ({data}) => {

  return (
    <>
      <section className="about">
        <div className="contain">
            <div className="main_image">
                <img src={data.image} alt={data.heading} />
            </div>
            <div className="main_cntnt">
                <h2>{data.heading}</h2>
                <p>{data.detail}</p>
            </div>
            <div className="abt_flex flex">
                <div className="colL">
                    <h2>{data.abt_heading}</h2>
                    <div dangerouslySetInnerHTML={ {__html: data.abt_detail} } />
                </div>
                <div className="colR">
                    <div className="image">
                        <img src={data.abt_image} alt={data.abt_heading} />
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default Intro;

import React, { useEffect , useState} from "react";

function Intro({ data }) {
  const [currentTab, setCurrentTab] = useState('1');
  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  }
  return (
    <>
       <section className="type_sec">
            <div className="contain">
                <div className="outer_nav_tabs">
                    <ul className="nav nav-tabs">
                        {data.tabs?.map((tab , i) =>{
                            return(
                            <li key={i}>
                                <button key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={(handleTabClick)}>{tab.tabTitle}</button>
                            </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="tab-content">
                    {data.tabs?.map((tab , i) =>{
                        return(
                        <div key={i}>
                            {currentTab === `${tab.id}` && <div>{tab.content}</div>}
                        </div>
                        );
                    })}
                </div>
            </div>
        </section>
    </>
  );
}

export default Intro;

import React, { useEffect , useState} from "react";

const Packages = ({data}) => {
    const [currentTab, setCurrentTab] = useState('1');
    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    }
  return (
    <>
      <section className="pricing">
        <div className="contain">
            <div className="price_heading text-center">
                <h5>
                    {data.title} <sub>{data.mini_title}</sub>
                </h5>
                <p>
                    {data.detail}
                </p>
            </div>
            <ul className="nav nav-tabs">
                {data.tabs?.map((tab , i) =>{
                return(
                    <li key={i}>
                    <button key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={(handleTabClick)}>
                        {tab.tabTitle}
                        {tab.sm_lbl ? <span>{tab.sm_lbl}</span> : ""}
                    </button>
                    </li>
                );
                })}
            </ul>
            <div className="tab-content">
                {data.tabs?.map((tab , i) =>{
                return(
                    <div key={i}>
                    {currentTab === `${tab.id}` && <div className="flex price_flex">{tab.content}</div>}
                    </div>
                );
                })}
            </div>
        </div>
      </section>
    </>
  );
};

export default Packages;

import React from "react";
import { Link } from "react-router-dom";

function SocialLinks() {
  return (
    <>
      <ul className="social_lnks">
        <li>
          <Link to="/">
            <img
              src="/images/facebook.svg"
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/">
            <img
              src="/images/instagram.svg"
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/">
            <img
              src="/images/twitter.svg"
              alt=""
            />
          </Link>
        </li>
        <li>
          <Link to="/">
            <img
              src="/images/linkedin.svg"
              alt=""
            />
          </Link>
        </li>
      </ul>
      
    </>
  );
}

export default SocialLinks;

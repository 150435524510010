import React, { useState } from "react";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Link } from "react-router-dom";

const Profile = () => {
    const [value, setValue] = useState()
  return (
    <>
      <div className="setting_blk wide_sm">
            <h4>Personal Info</h4>
            <form action="">
                <p>Logo</p>
                <div className="dp_flex">
                    <div className="dp_icon">
                        <img src="/images/user_dp.svg" alt="" />
                    </div>
                    <div className="btn_blk">
                        <button className="site_btn">Upload</button>
                        <button className="site_btn blank yellow_blank">Remove</button>
                    </div>
                </div>
                <div className="divide"></div>
                <div className="from_row row">
                    <div className="col-sm-6">
                        <div className="blk_form">
                            <h6>Display Name <span>(Visible to others)</span></h6>
                            <div className="form_blk">
                                <input
                                type="text"
                                name="display_name"
                                defaultValue="Francis"
                                className="input"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="blk_form">
                            <h6>Name <span> (Your given name)</span></h6>
                            <div className="form_blk">
                                <input
                                type="text"
                                name="name"
                                defaultValue="Francis Heather"
                                className="input"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="blk_form">
                            <h6>Job Title</h6>
                            <div className="form_blk">
                                <input
                                type="text"
                                name="job"
                                defaultValue="Engineer"
                                className="input"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="blk_form">
                            <h6>Company Size</h6>
                            <div className="form_blk">
                                <input
                                type="text"
                                name="company_size"
                                defaultValue="11-50"
                                className="input"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="blk_form">
                            <h6>Phone Number</h6>
                            <div className="form_blk">
                                <PhoneInput
                                    defaultCountry="US"
                                    className="input"
                                   value="+1 414-266-9847"
                                    onChange={setValue} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="blk_form">
                            <h6>Reviews</h6>
                            <div className="form_blk">
                                <div className="dim_lbl">Manage the reviews you’ve written for professionals, lands, and more.</div>
                            </div>
                        </div>
                        <Link to="/" className="manage_lnk">Manage</Link>
                    </div>
                </div>
                <div className="divide"></div>
                <div className="btn_blk text-right cell_wide_full">
                    <button type="submit" className="site_btn">Save changes</button>
                </div>
            </form>
      </div>
    </>
  );
};

export default Profile;
import React ,{ useState } from "react";
import { Link } from "react-router-dom";
const Form = () => {
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    alert(inputs);
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
            <div className="blk_form">
              <h6>Email</h6>
              <div className="form_blk">
                  <input
                      type="text"
                      className="input"
                      onChange={handleChange}
                      placeholder="hi@example.com"
                  />
              </div>
            </div>
            <div className="blk_form">
              <h6>Password</h6>
                <div className="form_blk pass_blk">
                    <input
                    type="password"
                    name="password"
                    id="password"
                    onChange={handleChange}
                    className="input"
                    placeholder="Enter password"
                    />
                    <i className="icon-eye" />
                </div>
            </div>
            <div className="forgot">
              <Link to="/">Forgot Password?</Link>
            </div>
            <div className="btn_blk">
              <button className="site_btn" type="submit">
                Login
              </button>
              <button className="site_btn blank" type="button">
                <img src="/images/google.svg" alt="" /> Continue with
                Google
              </button>
            </div>
            <div className="dont_acc">
              <p>
                Don’t have an account? <Link to="/signup">Get started today</Link>
              </p>
            </div>
      </form>
    </>
  );
};

export default Form;

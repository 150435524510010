import React from "react";
import { Link } from "react-router-dom";

const Story = ({data}) => {

  return (
    <>
      <section className="abt_story">
        <div className="contain">
            <div className="flex">
                <div className="colL">
                    <div className="image">
                        <img src={data.left_image} alt="" />
                    </div>
                    <div dangerouslySetInnerHTML={ {__html: data.story_detail} } />
                    <Link to="/">{data.lnk_lbl}</Link>
                </div>
                <div className="colR">
                    <h2>{data.heading}</h2>
                    <div className="image">
                        <img src={data.right_image} alt="" />
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default Story;

import React from "react";
import { Link } from "react-router-dom";

const Posts = ({data}) => {
  return (
    <>
    <div className="flex post_flex">
        {data?.map((val , i) => {
            return(
                <div className="col" key={i}>
                    <div className="post_blk">
                        <Link to="/" className="image">
                            <img src={val.image} alt="" />
                        </Link>
                        <div className="cntnt">
                            <div className="cat_lbl">{val.category}</div>
                            <h4><Link to="/">{val.title}</Link></h4>
                            <p>{val.intro}</p>
                        </div>
                    </div>
                </div>
            );
        })}
    </div>
      
    </>
  );
};

export default Posts;

import { map } from "highcharts";
import React from "react";
import { Link } from "react-router-dom";

const SocialInfo = ({data}) => {
  return (
    <>
      <section className="social_info_sec">
        <div className="contain">
            <div className="flex">
                {data?.map((val,i) => {
                    return (
                    <div className="col" key={i}>
                        <div className="inner">
                            <div className="sm_icon">
                                <img src={val.image} alt={val.title} />
                            </div>
                            <div className="cnt">
                                <h5>{val.title}</h5>
                                <p>{val.text}</p>
                                <Link to={val.link}><span>{val.link_title}</span><img src="/images/arrow_mini.svg" alt="" /></Link>
                            </div>
                        </div>
                    </div>
                    );
                })}
                
            </div>
        </div>
      </section>
    </>
  );
};

export default SocialInfo;

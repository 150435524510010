import React from "react";
import { Link } from "react-router-dom";

const ExportInfo = () => {
  return (
    <>
        <div className="top_strip_export">
            <div className="contain-fluid">
                <div className="flex">
                    <Link to="/">
                        <img src="/images/back_arrow.svg" alt="" />
                        <span>Export</span>
                    </Link>
                    <div className="label_info">
                        <small>2300 export creedit available</small>
                        <div className="pro_ress"></div>
                    </div>
                </div>
            </div>
        </div>
        <section className="export_sec">
            <div className="contain-fluid">
                <form action="">
                    <div className="row form_row field_row cell_form_row">
                        <div className="col-md-5">
                            <div className="form_blk">
                                <h6 className="lbl_heading">Name</h6>
                                <input type="text" className="input" value={"Reonomy Export 10/10/2022"}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form_blk">
                                <h6 className="lbl_heading">Properties to Export</h6>
                                <input type="text" className="input" value={"4"}/>
                            </div>
                        </div>
                    </div>
                    <div className="br"></div>
                    <div className="file_output_row">
                        <h6 className="lbl_heading">File Output</h6>
                        <div className="btn_lbl_blk">
                            <button className="btn_lbl active">Excel (XLSX)</button>
                            <button className="btn_lbl">CSV</button>
                        </div>
                    </div>
                    <div className="br"></div>
                    <div className="preview_blk">
                        <h6 className="lbl_heading">Preview</h6>
                        <div className="outer_table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Reonomy ID</th>
                                        <th>Link</th>
                                        <th>Address_full</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>b7 4uh4ihr 5iuh54-wert12-567hhlk3lo1</td>
                                        <td><Link to="/">https://www.figma.com/file/cqUjw9BMdTwvxo9fmn7Ob8/Rhino-Recon-%7C-Web-app</Link></td>
                                        <td>79 Street Robin Rd, West harford, CT 9908</td>
                                    </tr>
                                    <tr>
                                        <td>b7 4uh4ihr 5iuh54-wert12-567hhlk3lo1</td>
                                        <td><Link to="/">https://www.figma.com/file/cqUjw9BMdTwvxo9fmn7Ob8/Rhino-Recon-%7C-Web-app</Link></td>
                                        <td>79 Street Robin Rd, West harford, CT 9908</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="btn_blk btn_lst_export">
                        <button className="site_btn blank yellow_blank">Excel tab</button>
                        <button className="site_btn">Properties</button>
                        <button className="site_btn blank yellow_blank">Owner contacts</button>
                        <button className="site_btn blank yellow_blank">Occupants</button>
                        <button className="site_btn blank yellow_blank">Occupants contacts</button>
                    </div>
                    <div className="br"></div>
                    <div className="property_fields">
                        <div className="head_field">
                            <h6>Property fields</h6>
                            <Link to="#">Restore defaults</Link>
                        </div>
                        <div className="check_flex">
                            <div className="check_inner">
                                <div className="lbl_btn">
                                    <input type="checkbox" name="property" id="location"/>
                                    <label htmlFor="location">Location</label>
                                </div>
                                <div className="lbl_btn">
                                    <input type="checkbox" name="property" id="property_notes"/>
                                    <label htmlFor="property_notes">My properties (Notes and Labels)</label>
                                </div>
                                <div className="lbl_btn">
                                    <input type="checkbox" name="property" id="building_lot"/>
                                    <label htmlFor="building_lot">Building and Lot</label>
                                </div>
                                <div className="lbl_btn">
                                    <input type="checkbox" name="property" id="owners_contact"/>
                                    <label htmlFor="owners_contact">Owners and Contact Info</label>
                                </div>
                            </div>
                            <div className="check_inner">
                                <div className="lbl_btn">
                                    <input type="checkbox" name="property" id="sale"/>
                                    <label htmlFor="sale">Sales</label>
                                </div>
                                <div className="lbl_btn">
                                    <input type="checkbox" name="property" id="debt"/>
                                    <label htmlFor="debt">Debt</label>
                                </div>
                                <div className="lbl_btn">
                                    <input type="checkbox" name="property" id="distressed"/>
                                    <label htmlFor="distressed">Distressed</label>
                                </div>
                                <div className="lbl_btn">
                                    <input type="checkbox" name="property" id="tax"/>
                                    <label htmlFor="tax">Tax</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn_blk">
                        <button className="site_btn">Export</button>
                    </div>

                </form>
            </div>
        </section>
    </>
  );
};

export default ExportInfo;

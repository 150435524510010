import React  from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
function CommercialTabs({data}) {
    
  return (
    <>
      <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1, times: [2, 3, 2] }}
    exit={{ opacity: 0 }}
    >
      <div className="flex">
        <div className="colL">
            <div className="image">
                <img src={data.image} alt="" />
            </div>
            <div className="play_lbl flex">
            <div className="play_ic">
                <img src={data.play_img} alt="" />
            </div>
            <div className="cntnt">
                <h6>{data.play_title}</h6>
                <p>{data.play_txt}</p>
            </div>
            </div>
            <div className="be_more">
            <div className="inner">
                <h6>{data.home_title}</h6>
                <p>{data.home_txt}</p>
            </div>
            <div className="home_ico">
                <img src={data.home_img} alt="" />
            </div>
            </div>
        </div>
        <div className="colR">
          <h2>{data.title}</h2>
          <p>
          {data.detail}
          </p>
          <div className="btn_blk">
              <Link to={data.button_link} className="site_btn">
                  {data.button_label} <img src="/images/right-angle.svg" alt="" />
              </Link>
          </div>
        </div>
      </div>
    </motion.div>
    </>
  );
}

export default CommercialTabs;
